import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { systemService } from 'services/system.service';
import { Transformable } from 'components/Modules/transformable';
import { Widget } from '../components/Widget';

export function SystemBuilder() {
  const navigate = useNavigate();
  const [pages, setPages] = useState(null);
  const [page, setPage] = useState(null);

  useEffect(() => {
    systemService
      .getCanvases()
      .then((pages) => {
        setPages(pages);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <Widget>
      <Routes>
        <Route
          index
          element={
            <div className="p-2">
              <div className="flex">
                <div className="w-1/3">
                  <button
                    className=" m-2 px-6 py-2 border-2 border-green-500 rounded-full hover:bg-green-200"
                    onClick={() => {
                      setPage(null);
                      navigate('build');
                    }}
                  >
                    Legg til
                  </button>
                </div>
                <h1 className="w-1/3 text-center mt-3">SystemBygger</h1>
              </div>

              <Transformable.List pages={pages} setPages={setPages} setPage={setPage} />
            </div>
          }
        ></Route>
        <Route path={'build'} element={<Transformable.Edit page={page} />}></Route>
        <Route path={'view'} element={<Transformable.View items={page && page.items} />}></Route>
      </Routes>
    </Widget>
  );
}
