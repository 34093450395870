import { useEffect, useRef } from 'react';
import { usePopper } from 'react-popper';
import { useOutsideAlerter } from '../Hooks/useOutsideAlerter';

export function Popover({ children, trigger, show, setShow, isLocked }) {
  let referenceElement = useRef();
  let popperElement = useRef();
  let { styles, attributes } = usePopper(referenceElement, popperElement);
  const outsidePopover = useOutsideAlerter(popperElement);
  const outsideTrigger = useOutsideAlerter(referenceElement);

  // useEffect(() => {
  //   setShow(!show);
  // }, [children.setShow]);

  useEffect(() => {
    if (outsidePopover && outsideTrigger) {
      setShow(false);
    }
  }, [outsidePopover, outsideTrigger, setShow]);

  return (
    <>
      <div ref={referenceElement} onClick={() => !isLocked && setShow(!show)}>
        {trigger}
      </div>
      {show && (
        <div
          ref={popperElement}
          style={styles.popper}
          {...attributes.popper}
          className="bg-white transform -translate-y-full overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 p-2 -mt-1 z-50"
        >
          {children}
        </div>
      )}
    </>
  );
}
