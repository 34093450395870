import { useMemo, useState } from 'react';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from 'react-table';
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid';
import { SortIcon, SortUpIcon, SortDownIcon } from 'components/Elements/icons';
import { classNames } from 'utils/classNames';
import { Card, CardContainer, TextBox } from './Card';
import { Button, PageButton } from 'components/Elements/Button';
import { useNavigate } from 'react-router';
import { useListView } from 'components/Hooks/useWindowDimensions';
import { accountService } from 'services';
import { Select } from 'components/Elements/Select';
import { useAppContext } from 'components/Context/AppWrapper';
import { hasAccess, Role } from 'utils';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { GetSystemStatus } from 'utils/status';
import { statusEscalate } from 'utils/statusEscalte';

// Define a default UI for filtering
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <label className=" flex w-full md:w-auto">
      <input
        type="text"
        className="w-full md:w-auto appearance-none border border-gray-300 hover:border-gray-500 block text-gray-400 focus:text-gray-800 rounded-lg py-2 px-3 leading-tight outline-1 outline-sky-600"
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={'Søk'}
      />
    </label>
  );
}

// TODO: check this for Filter, customer, if undefines.
// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id, render } }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-600">{render('Header')}: </span>
      <select
        className="text-gray-600 rounded-lg border border-gray-300 hover:border-gray-500 focus:border-sky-600 focus:border hover:cursor-pointer outline-none py-2 px-1 box-border"
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">Alle</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
}

export function StatusPill({ value, statusFunction, fetchStatus }) {
  const {title, style} = statusEscalate(statusFunction(value, fetchStatus));
  return (
    <span
      className={classNames(
        'py-2 px-3 uppercase leading-wide font-bold text-xs rounded-full shadow-sm',
        style
      )}
    >
      {title}
    </span>
  );
}

export function DatePill({ value, cell, listView }) {
  return listView === undefined ? (
    <div className="text-sm text-gray-500 dark:text-white">{new Date(value).toLocaleString()}</div>
  ) : (
    <div className="text-[3vw] text-gray-500 dark:text-white">
      {new Date(value).toLocaleString()}
    </div>
  );
}

export function TableTitle({ value, listView }) {
  return listView === undefined ? (
    <div className="text-sm text-gray-600 dark:text-white">{value}</div>
  ) : (
    <div className="text-xl inline-block text-gray-700 dark:text-white word-breaks font-medium w-full">Dette er en lang lang tekst som</div>
  );
}

export function ButtonGroup({ value }) {
  return value.map((elm, idx) => <Button text={elm.text} key={idx}></Button>);
}

export function EditButton({ value }) {
  const navigate = useNavigate();
  // return <Button onClickCapture={() => navigate(`${value}/edit`)}>Rediger</Button>;
  return (
    <Tooltip title="Rediger" onClickCapture={() => navigate(`${value}/edit`)}>
      <IconButton>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
        </svg>
      </IconButton>
    </Tooltip>
  );
}

export function AvatarCell({ value, column, row }) {
  return (
    <div className="flex">
      <div className="flex-shrink-0 h-10 w-10">
        <img className="h-10 w-10 rounded-full" src={row.original[column.imgAccessor]} alt="" />
      </div>
      <div className="ml-4">
        <div className="text-sm font-medium text-gray-900 dark:text-gray-100">{value}</div>
        <div className="text-sm text-gray-500 dark:text-gray-300">{row.original[column.emailAccessor]}</div>
      </div>
    </div>
  );
}

export function CustomCell({ value, column, row }) {
  return (
    <div className="flex">
      <div className="flex-shrink-0 h-10 w-10">{row.original.name}</div>
    </div>
  );
}

function Table({ columns, data, handleClick = null, cardsAsDefault = false, statusFunction = null, addToHeader = null, addButton = null }) {
  // Use the state and functions returned from useTable to build your UI
  const listView = useListView();
  // const customer = customerService.customerValue;
  const { status: fetchStatus } = useAppContext();
  const user = accountService.userValue;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination // new
  );

  // Render the UI for your table
  return (
    <>
      <div className="flex gap-x-4 items-center flex-col md:flex-row flex-wrap gap-y-2">
        <div className='flex flex-row md:flex-none gap-x-2 items-center w-full md:w-auto '>
          <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
          {addButton && (
            <Tooltip
              className="justify-self-end"
              onClick={() => {
                addButton();
              }}
              title="Legg til"
            >
              <IconButton>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                </svg>
              </IconButton>
            </Tooltip>
          )}
        </div>

        { addToHeader &&
          <div className='flex w-full md:w-auto'>
            {addToHeader}
          </div>
        }

        <div className='flex flex-row md:flex-none gap-x-4 items-center w-full md:w-auto flex-wrap'>
          {headerGroups.map((headerGroup) =>
            headerGroup.headers.map((column) =>
              column.Filter ? (
                <div key={column.id}>
                  {column.render('Filter')}
                </div>
              ) : null
            )
          )}
        </div>
      </div>
      {/* table */}
      <div className="mt-4 flex flex-col">
        <div className="-my-2 overflow-x-auto ">
          <div className="py-2 align-start text-left inline-block min-w-full">
            {listView ? (
              <div className="border overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table {...getTableProps()} className="min-w-full divide-y divide-gray-200 ">
                  <thead className="bg-gray-50">
                    {headerGroups.map((headerGroup, headerGroupIndex) => (
                      <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                        {headerGroup.headers.map((column, columnIndex) => (
                          // Add the sorting props to control sorting. For this example
                          // we can add them into the header props
                          <React.Fragment key={columnIndex}>
                            { hasAccess(user, (column.access ? column.access : Role.User)) && (
                              <th
                                scope="col"
                                className="group px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                              >
                                <div className="flex items-center justify-between">
                                  {column.render('Header')}
                                  {/* Add a sort direction indicator */}
                                  {/* {console.log(column.Header)} */}
                                  {!(column.Header === '') &&
                                    <span className='ml-2 mr-auto'>
                                      {column.isSorted ? (
                                        column.isSortedDesc ? (
                                          <SortDownIcon className="w-3 h-3 text-gray-600" />
                                        ) : (
                                          <SortUpIcon className="w-3 h-3 text-gray-600" />
                                        )
                                      ) : !column.disableSortBy ? (
                                        <SortIcon className="w-3 h-3 text-gray-600 opacity-0 group-hover:opacity-100" />
                                      ) : (
                                        ''
                                      )}
                                    </span>
                                  }
                                </div>
                              </th>
                            )}
                          </React.Fragment>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                    {page.map((row, rowIndex) => {
                      // new
                      prepareRow(row);
                      return (
                        <tr
                          key={rowIndex}
                          {...row.getRowProps()}
                          onClick={() => {
                            handleClick && handleClick(row.original);
                          }}
                          className={classNames(
                            handleClick && 'cursor-pointer',
                            'bg-white hover:bg-gray-200 dark:hover:bg-gray-700 dark:bg-gray-800 dark:text-white'
                          )}
                        >
                          {row.cells.map((cell, cellIndex) => {
                            return (
                              <React.Fragment key={cellIndex}>
                                { hasAccess(user, (cell.column.access ? cell.column.access : Role.User)) && (
                                  <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap" role="cell">
                                    {cell.column.Cell.name === 'defaultRenderer' ? (
                                      <div className="text-sm text-gray-600 dark:text-white">{cell.render('Cell')}</div>
                                    ) : (
                                      cell.render('Cell', { status, statusFunction, fetchStatus })
                                    )}
                                  </td>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>
                <CardContainer>
                  {page.map((row, i) => {
                    //console.log(row.cells.filter((elem) => !elem.column.status !== undefined));
                    prepareRow(row);
                    return (
                      <Card
                        key={i}
                        className={'m-1'}
                        props={row.original}
                        handleClick={handleClick}
                        status={ row.values.status !== undefined}
                        statusFunction={statusFunction && statusFunction(row.original.id, fetchStatus)}

                      >
                        {row.cells
                          .filter((elem) => !elem.column.hideOnCard)
                          .map((cell, cellIndex) => (
                            <div {...cell.getCellProps()} className="" role="cell" key={cellIndex}>
                              {cell.column.Cell.name === 'defaultRenderer' ? (
                                <div
                                  className={classNames(
                                    'text-sm text-gray-700 dark:text-gray-200',
                                    cell.column.cardClassName
                                  )}
                                >
                                  <TextBox
                                    title={cell.column.Header}
                                    text={cell.render('Cell')}
                                    cardLabel={cell.column.cardLabel}
                                  />
                                </div>
                              ) : (
                                cell.render('Cell', { listView })
                              )}
                            </div>
                          ))}
                      </Card>
                    );
                  })}
                </CardContainer>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Pagination */}
      {listView ? (
        <div className="mt-1 py-3 flex items-center justify-between">
          <div className="flex-1 flex justify-between sm:hidden">
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
              Forrige
            </Button>
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              Neste
            </Button>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div className="flex gap-x-2 items-baseline">
              <span className="text-sm text-gray-600 w-full">
                Side <span className="font-medium">{state.pageIndex + 1}</span> av{' '}
                <span className="font-medium">{pageOptions.length}</span>
              </span>
              <Select state={state.pageSize} options={[5, 10, 50]} setState={setPageSize}></Select>
            </div>
            <div>
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <PageButton className="rounded-l-md" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  <span className="sr-only">First</span>
                  <ChevronDoubleLeftIcon className={`${canPreviousPage ? 'text-gray-500' : 'text-gray-300'} h-5 w-5`} aria-hidden="true" />
                </PageButton>
                <PageButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className={`${canPreviousPage ? 'text-gray-500' : 'text-gray-300'} h-5 w-5`} aria-hidden="true" />
                </PageButton>
                <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className={`${canNextPage ? 'text-gray-500' : 'text-gray-300'} h-5 w-5`} aria-hidden="true" />
                </PageButton>
                <PageButton className="rounded-r-md" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                  <span className="sr-only">Last</span>
                  <ChevronDoubleRightIcon className={`${canNextPage ? 'text-gray-500' : 'text-gray-300'} h-5 w-5`} aria-hidden="true" />
                </PageButton>
              </nav>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}

export default Table;
