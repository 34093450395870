import { classNames } from 'utils/classNames';
import { statusEscalate } from 'utils/statusEscalte';

export function CardFooter({ status }) {
  var {title, style} = statusEscalate(status);
  return (
    <>
      {status && 
        <div className={classNames('text-center p-px ', style)}>
          <p className=" text-md">{title}</p>
        </div>
      }
    </>
  );
}
