import { LocationTable } from 'components/Modules/table/LocationTable';
import { Widget } from '../components/Widget';
import { Route, Routes, useParams } from 'react-router-dom';
import { SystemTable } from 'components/Modules/table/SystemTable';
import { AddEditSystem } from 'components/Modules/addedit/AddEditSystem';
import { useDashboardContext } from '../context';
import { useEffect } from 'react';
import { customerService } from 'services';
import { AddEditLocation } from 'components/Modules/addedit/AddEditLocation';

export function Locations({ displayedPath }){
  return (
    <Widget>
      <Routes>
        <Route index path={'/'} element={<LocationTable />} />
        <Route path={':locationId'} element={<Systems displayedPath={displayedPath} />} />
        <Route path={':locationId/:systemId/edit'} element={<AddEditSystem />} />
        <Route path={':locationId/add'} element={<AddEditSystem />} />
        <Route path={':locationId/edit'} element={<AddEditLocation />} />
        <Route path={'add'} element={<AddEditLocation />} />
      </Routes>
    </Widget>
  );
}

export function Systems({ displayedPath }) {
  const { setPathname } = useDashboardContext();
  const customer = customerService.customerValue;
  const { locationId } = useParams();
  const location = customer.locations.filter(elem => elem.id === parseInt(locationId))[0];
  
  useEffect(() => {
    setPathname([displayedPath, location?.title].flat());
    return () => {
      setPathname(displayedPath);
    };
  }, [location, displayedPath, setPathname]);

  return (
    <SystemTable />
  );
}