import { Form, Formik } from 'formik';
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { FormInput, FormGroup } from 'components/Elements/forms';
import { Popover } from 'components/Modules/Popover';
import { ActiveDays } from './ActiveDays';
import { ruleService } from 'services/rule.service';

export function AddEditRule({ rule, rules, setRules, sensor, isLocked = false }) {
  const [days, setDays] = useState('0123456');
  const [show, setShow] = useState(false);

  const [initialValues, setInitialValues] = useState({
    min: '',
    max: '',
    startTime: '',
    endTime: '',
    sensitivity: 1,
  });

  // const validationSchema = Yup.object().shape({
  //  min: Yup.number().required("Skriv inn min-verdi"),
  //  max: Yup.number().required("Skriv inn max-verdi"),
  //  sensitivity: Yup.number().min(1).max(100).required("Skriv inn tall mellom 1-100"),
  // });

  useEffect(() => {
    if (rule) {
      const updatedRule = { ...rule, min: rule.min.toFixed(1), max: rule.max.toFixed(1) };
      setInitialValues(updatedRule);
      setDays(rule.days);
    }
  }, [rule]);

  function onSubmit(inputs) {
    const values = { ...inputs, min: parseFloat(inputs.min).toFixed(1), max: parseFloat(inputs.max).toFixed(1) };
    if (rule) {
      ruleService
        .updateRule(rule.id, { ...values, days })
        .then(() => {
          setRules(
            rules.map((y) => {
              if (y.id === rule.id) {
                return { ...values, days };
              } else {
                return y;
              }
            })
          );
        })
        .catch((err) => toast.error(err));
    } else {
      ruleService
        .addRule({ ...values, days, sensorId: sensor.id })
        .then((x) => {
          setRules([...rules, x]);
          setShow(false);
        })
        .catch((err) => toast.error(err));
    }
  }

  function removeAlarm(ruleId) {
    ruleService.removeRule(ruleId).then(() => {
      setRules(rules.filter((rule) => rule.id !== ruleId));
    });
  }

  return (
    <div className="relative">
      <Popover
        isLocked={isLocked}
        trigger={
          rule ? (
            <div
              className={`flex flex-col rounded-lg shadow transition duration-100 ease-in p-2 pt-1 space-y-1 hover:bg-gray-100 cursor-pointer ${
                rule.active ? 'border' : ''
              } ${(rule.sensor && rule.sensor.status) ? 'border-red-500' : 'border-green-500'}`}
            >
              <div className="flex justify-center">
                <p className="cursor-pointer text-sm font-medium text-gray-900">{`[${rule.min},${
                  rule.max
                }] @ ${rule.startTime.slice(0, 5)} - ${rule.endTime.slice(0, 5)}`}</p>
              </div>
              <div>
                <ActiveDays dayString={rule.days} viewOnly />
              </div>
            </div>
          ) : rules && rules.length === 0 ? (
            <div className="flex flex-col shadow rounded-lg p-2 mt-1 hover:bg-gray-100 cursor-pointer">
              <div className="flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                </svg>
              </div>
              <div className="">
                <p className="text-sm font-medium text-gray-900">Legg til alarm</p>
              </div>
            </div>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
          )
        }
        show={show}
        setShow={setShow}
      >
        <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
          {({ errors, touched }) => (
            <FormGroup>
              <Form>
                <div className="flex flex-col bg-white p-4 pb-0">
                  <div className="flex justify-between">
                    <p>Legg til alarm</p>
                    {rule && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 hover:text-red-500 cursor-pointer"
                        onClick={() => removeAlarm(rule.id)}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    )}
                  </div>
                  <div className="w-62">
                    <div className="flex space-x-3">
                      <FormInput
                        name="min"
                        title="Min"
                        type="number"
                        errors={errors.min}
                        touched={touched.min}
                        placeholder="Nedre verdi"
                      />
                      <FormInput
                        name="max"
                        title="Max"
                        type="number"
                        errors={errors.max}
                        touched={touched.max}
                        placeholder="Øvre verdi"
                      />
                    </div>
                    <div className="flex space-x-3">
                      <FormInput name="startTime" title="Start" type="time" errors={errors.startTime} touched={touched.startTime} />
                      <FormInput name="endTime" title="Slutt" type="time" errors={errors.endTime} touched={touched.endTime} />
                    </div>
                    <div className="flex">
                      <FormInput
                        name="sensitivity"
                        title="Antall avvik før alarm (15 min)"
                        type="number"
                        errors={errors.sensitivity}
                        touched={touched.sensitivity}
                        placeholder="1"
                      />
                    </div>
                    <ActiveDays dayString={days} setDayString={setDays} />
                  </div>
                  <div className="p-4 bg-gray-50 flex space-x-3 justify-around">
                    <button type="button" onClick={() => setShow(false)} className="ring-2 ring-red-500 flow-root px-5 py-2 transition duration-150 ease-in-out rounded-md hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                      <span className="flex items-center">
                        <span className="text-sm font-medium text-gray-900">Avbryt</span>
                      </span>
                    </button>
                    <button type="submit" className='ring-2 ring-green-500 flow-root px-5 py-2 transition duration-150 ease-in-out rounded-md hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50'>
                      <span className="flex items-center">
                        <span className="text-sm font-medium text-gray-900">Lagre</span>
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            </FormGroup>
          )}
        </Formik>
      </Popover>
    </div>
  );
}
