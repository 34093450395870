export const defaultTube = {
  r: 0,
  x: 500,
  y: 150,
  w: 800,
  h: 100,
  minW: 200,
  minH: 50,
  type: 'tube',
  style: {
    fill: '#aaa',
  },
  render: Tube,
};

export function Tube({ id, state, selected, view }) {
  const position = {
    lt: { x: state.x - state.w / 2 + 50, y: state.y - state.h / 2 },
    lm: { x: state.x - state.w / 2, y: state.y },
    rt: { x: state.x + state.w / 2, y: state.y - state.h / 2 },
    rm: { x: state.x + state.w / 2 - 50, y: state.y },
    rb: { x: state.x + state.w / 2, y: state.y + state.h / 2 },
    lb: { x: state.x - state.w / 2 + 50, y: state.y + state.h / 2 },
  };

  return (
    <>
      {position && position.lt.x && (
        <polygon
          id={id}
          className="item"
          points={`${position.lm.x},${position.lm.y} ${position.lt.x},${position.lt.y} ${position.rt.x},${position.rt.y} ${position.rm.x},${position.rm.y} ${position.rb.x},${position.rb.y} ${position.lb.x},${position.lb.y}`}
          fill={state.style.fill}
          stroke={selected === id ? '#77c9f2' : 'none'}
          strokeDasharray="15, 15"
          strokeWidth={3}
          cursor={view ? 'default' : 'pointer'}
          transform={`rotate(${state.r} ${state.x} ${state.y})`}
        ></polygon>
      )}
    </>
  );
}
