const DashboardIcon = () => {
  return (
    // <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    //   <g>
    //     <rect strokeWidth="35" rx="0" id="svg_1" height="180" width="180" y="36" x="36" stroke="currentColor" fill="none"/>
    //     <rect strokeWidth="35" rx="0" id="svg_5" height="180" width="180" y="296" x="296" stroke="currentColor" fill="none"/>
    //     <rect strokeWidth="35" rx="0" id="svg_6" height="180" width="180" y="36" x="296" stroke="currentColor" fill="none"/>
    //     <rect strokeWidth="35" rx="0" id="svg_7" height="180" width="180" y="296" x="36" stroke="currentColor" fill="none"/>
    //   </g>
    // </svg>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
    </svg>
  );
};

export default DashboardIcon;