import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { accountService, customerService } from 'services';
import toast from 'react-hot-toast';
import { deviceService } from 'services';
import { FormGroup, FormContainer, FormInput, FormSelect, FormSubmit } from 'components/Elements/forms';
import { AddEditHeader } from 'components/Modules/addedit/AddEditHeader';
import { useNavigate, useParams } from 'react-router';
import { Widget } from '../../components/Widget';
import { DeviceConfig } from './DeviceConfig';
import { DeviceTunnel } from './DeviceTunnel';
import { DeviceSensors } from './Sensors';

export function Device() {
  return (
    <>
      <Widget title="Konfigurasjon" cols="3">
        <DeviceConfig />
      </Widget>
      <Widget title="SSH" cols="2">
        <DeviceTunnel />
      </Widget>
      <Widget title="Sensorer">
        <DeviceSensors />
      </Widget>
    </>
  );
}