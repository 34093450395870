export const Role = {
  Root: 0,
  Admin: 1,
  Editor: 2,
  CustomerAdmin: 3,
  User: 4,
};

export const RoleName = {
  0: 'Superbruker',
  1: 'Enkeldrift Administrator',
  2: 'Enkeldrift Support',
  3: 'Admin',
  4: 'Bruker',
};

export function hasAccess(user, accessLevel, maxAccessLevel) {
  return (user?.role <= accessLevel && (maxAccessLevel !== undefined ? user?.role > maxAccessLevel : true));
}
