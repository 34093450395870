import { Contact } from './Contact';
import { Route, Routes } from 'react-router';
import { SystemTable } from 'components/Modules/table/SystemTable';
import { CustomDashboard } from './MyDashboard';
import { Overview } from './Overview';
import { Alarms } from './Alarmlog';
import { UserTable } from './MyDashboard/UserTable';
import { Locations } from './Locations';
import { accountService } from 'services';
import { hasAccess, Role } from 'utils';
import { Users } from './Users';
import { Devices } from './Devices';
import { Customers } from './Customers';
import { Message } from './Message';
import { SystemBuilder } from './SystemBuilder';
import { AdminOverview } from './Overview/AdminOverview';
import { DashboardWrapper } from './components/DashboardWrapper';
import { DashboardContainer } from './context';
import { routes } from './routes';
import { Fragment } from 'react';

export function Dashboard() {
  const user = accountService.userValue;
  // const [userNavigation, setUserNavigation] = useState(initialUserNavigation);

  // useEffect(() => {
  //   dashboardService.getAll().then((data) => {
  //     setDashboards(data);
  //   });
  // }, []);

  const activeRoutes = [];
  routes.map(route => {
    if (hasAccess(user, route.access, route.noAccess)) {
      activeRoutes.push(route);
    }
  });

  return (
    <DashboardContainer>
      <Routes>
        {activeRoutes?.map((elem) => (
          <Route key={elem.path} path={elem.path} element={
            <DashboardWrapper title={elem.name} currentPath={elem.path.split('/')[0]} defaultPathname='Dashbord' defaultPath="dashbord" menuItems={activeRoutes}>
              <elem.element displayedPath={['Dashbord', elem.name]} />
            </DashboardWrapper>
          } />
        ))}
      </Routes>
    </DashboardContainer>
  );
  // if(hasAccess(user, Role.Admin)) {
  //   return (
  //     <Routes>
  //       <Route index path={'/'} element={<AdminOverview />} />
  //       <Route path={'users/*'} element={<Users />} />
  //       <Route path={'devices/*'} element={<Devices />} />
  //       <Route path={'customers/*'} element={<Customers />} />
  //       <Route path={'message/*'} element={<Message />} />
  //       <Route path={'canvas/*'} element={<SystemBuilder />} />
  //     </Routes>
  //   );
  // }

  // return (
  //   <Routes>
  //     {/* <Route path={`/`} element={<Overview />}></Route> */}
  //     <Route index element={<Overview />} />
  //     <Route path="locations" element={<Locations />} />
  //     <Route path="locations/:locationId" element={<SystemTable />} />
  //     <Route path={':userId/:dashboardId'} element={<CustomDashboard />}></Route>
  //     <Route
  //       path={':userId/:dashboardId/preview'}
  //       element={<CustomDashboard preview={true} />}
  //     ></Route>
  //     <Route path="contact" element={<Contact />}></Route>
  //     <Route path="alarms" element={<Alarms />} />
  //     <Route path="users" element={<UserTable />} />
  //     {/* <Route path={`locations`} element={<LocationTable />}></Route>
  // 			<Route path={`locations/:locationId`} element={<SystemTable />}></Route> */}
  //   </Routes>
  // );
}
