import { accountService } from 'services';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { hasAccess, Role } from 'utils';

import cloudImage from './images/cloud.png';
import dashboardImage from './images/dashboard.png';
import notificationImage from './images/notification.png';
import enkeldriftImage from './images/enkeldrift.png';
import toast from 'react-hot-toast';
import { LinkButton, SubmitButton } from 'components/Elements/Button';

export function Home() {
  const user = accountService.userValue;

  const initialValues = {
    name: '',
    from: '',
    message: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Skriv inn navn'),
    from: Yup.string().email('Ugyldig e-postadresse').required('Skriv inn e-post'),
    message: Yup.string()
      .min(6, 'Melding må være minst 6 tegn')
      .max(500, 'Melding må være under 500 tegn')
      .required('Skriv inn melding'),
  });

  function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
    setStatus();
    resetForm();

    accountService
      .sendEmail(fields)
      .then(() => {
        setSubmitting(false);
        toast.success('E-post sendt. Kopi sendt til ' + fields.from, {
          keepAfterRouteChange: true,
        });
      })
      .catch((error) => {
        setSubmitting(false);
        toast.error(error);
      });
  }

  return (
    <div className="bg-blue-300 bg-opacity-10 overflow-x-hidden">
      <div className="flex flex-col lg:flex-row max-w-7xl mx-auto px-6 py-12 md:py-20">
        <div className="lg:w-1/2 lg:p-5 place-self-center">
          <div className="lg:float-right w-full lg:w-9/12">
            <p className="text-5xl xs:text-7xl text-left leading-none lg:leading-4.5">Overvåkning gjort&nbsp;enkelt</p>
            <p className="text-2xl text-left leading-tight mt-8">Driftsovervåkning av alle ventilasjonsanlegg uansett fabrikat eller alder</p>
            <div className="flex lg:pr-40 space-x-3 mt-6">
              <LinkButton className="w-26" to={user ? '/dashbord' : '/account/login'} fill>
                {user ? 'Dashbord' : 'Logg inn'}
              </LinkButton>
              <LinkButton to="/demo" className="w-26">Lær mer</LinkButton>
            </div>
          </div>
        </div>
        <div className="invisible lg:visible h-0 lg:h-fit lg:w-1/2 place-self-center">
          <div className='w-9/12'>
            <div className="w-96">
              <img alt="Technology illustration" src={enkeldriftImage} />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="flex flex-col place-self-center space-y-10 max-w-7xl mx-auto px-6 py-12 md:py-20">
          <div className="w-full">
            <p className="text-7xl font-light text-center">Vi tilbyr</p>
          </div>
          <div className="flex flex-col lg:flex-row space-y-6 pt-6 lg:space-y-0 lg:space-x-10 mx-auto">
            <div className="w-72 shadow-xl border-t-2 border-t-gray-100 rounded-2xl p-8">
              <img className="invisible md:visible h-0 md:h-40 m-auto" src={dashboardImage} alt="Dashbord"></img>
              <div className="">
                <h5 className="font-bold">Dashbord</h5>
                <p>Enkelt og oversiktlig dashbord skreddersydd til ditt behov!</p>
              </div>
            </div>
            <div className="w-72 shadow-xl border-t-2 border-t-gray-100 rounded-2xl p-8">
              <img className="invisible md:visible h-0 md:h-40 m-auto" src={cloudImage} alt="Skylagring"></img>
              <div className="">
                <h5 className="font-bold">Skylagring</h5>
                <p>Tilgang til dine data, hvor som helst, når som helst!</p>
              </div>
            </div>
            <div className="w-72 shadow-xl border-t-2 border-t-gray-100 rounded-2xl p-8">
              <img className="invisible md:visible h-0 md:h-40 m-auto" src={notificationImage} alt="Varsler"></img>
              <div className="card-body">
                <h5 className="font-bold">Varsler</h5>
                <p>Sett opp egne varslinger, og få beskjed direkte på e-post!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="contact" className="flex flex-col lg:flex-row max-w-7xl mx-auto px-6 py-12 md:py-20">
        <div className="lg:w-1/2 px-0 lg:px-5 place-self-center space-y-3">
          <div className="float-right space-y-3 px-0 lg:px-3">
            <p className="text-6xl font-light text-center">Kontakt oss</p>
            <p className="text-center">Ønsker du å vite mer om produktet? Ta kontakt med oss!</p>
          </div>
        </div>
        <div className="w-full sm:w-9/12 lg:w-1/2 px:0 lg:px-10 py:5 place-self-center space-y-3 mt-6 lg:mt-0">
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <div className="lg:w-3/4">
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2 ml-px hover:cursor-text">Navn</label>
                    <Field
                      type="text"
                      name="name"
                      placeholder="Navn"
                      className={
                        'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' +
                        (errors.name && touched.name ? ' border-red-600' : '')
                      }
                    ></Field>
                    {/* <ErrorMessage name="name" component="div" className=" " /> */}
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2 ml-px hover:cursor-text">E-post</label>
                    <Field
                      type="text"
                      name="from"
                      placeholder="E-post"
                      className={
                        'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' +
                        (errors.from && touched.from ? ' border-red-600' : '')
                      }
                    ></Field>
                    {/* <ErrorMessage name="from" component="div" className="invalid-feedback" /> */}
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2 ml-px hover:cursor-text">Melding</label>
                    <Field
                      type="textarea"
                      component="textarea"
                      name="message"
                      rows="3"
                      placeholder="Melding"
                      className={
                        'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' +
                        (errors.message && touched.message ? ' border-red-600' : '')
                      }
                    ></Field>
                    {/* <ErrorMessage name="message" component="div" className="invalid-feedback" /> */}
                  </div>
                  <div className="w-26">
                    <SubmitButton isSubmitting={isSubmitting}>Send</SubmitButton>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
