import { Route, Routes } from 'react-router-dom';
import { DeviceTable } from 'components/Modules/table/DeviceTable';
import { Widget } from '../components/Widget';
import { Device } from './Device';

export function Devices() {
  return (
    <Routes>
      <Route index path={'/'} element={<Widget><DeviceTable /></Widget>} />
      <Route path={':id'} element={<Device />} />
    </Routes>
  );
}
