import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { accountService, systemService } from 'services';
import toast from 'react-hot-toast';
import { FormContainer, FormGroup, FormInput, FormSelect, FormSubmit } from 'components/Elements/forms';
import { AddEditHeader } from './AddEditHeader';
import { useNavigate, useParams } from 'react-router';
import { customerService, deviceService } from 'services';
import { hasAccess, Role } from 'utils';

function AddEditSystem() {
  const navigate = useNavigate();
  const user = accountService.userValue;
  const { locationId } = useParams();
  const { systemId } = useParams();
  const isAddMode = !systemId;
  const [system, setSystem] = useState(null);
  const [devices, setDevices] = useState(null);

  useEffect(() => {
    if (!isAddMode) {
      // get user and set form fields
      systemService.getById(systemId).then((system) => {
        setSystem(system);
      });
    }
    async function fetchData() {
      setDevices(await deviceService.getAll());
    }
    if (hasAccess(user, Role.Admin)) {
      fetchData();
    }
  }, [isAddMode, systemId, user]);

  const initialValues = {
    title: system ? system.title : '',
    description: system ? system.description : '',
    deviceId: system ? system.deviceId : '',
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Skriv inn tittel'),
  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    if (isAddMode) {
      addSystem(fields, setSubmitting);
    } else {
      updateSystem(fields, setSubmitting);
    }
  }

  function addSystem(fields, setSubmitting) {
    customerService
      .addSystem({ ...fields, locationId })
      .then(() => {
        toast.success('Systemet ble lagt til');
        navigate(-1);
      })
      .catch((error) => {
        setSubmitting(false);
        toast.error(error);
      });
  }

  function updateSystem(fields, setSubmitting) {
    systemService
      .update(systemId, fields)
      .then(() => {
        toast.success('Oppdatering vellykket');
        navigate(-1);
      })
      .catch((error) => {
        setSubmitting(false);
        toast.error(error);
      });
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
      {({ errors, touched, values, isSubmitting }) => {
        return (
          <FormContainer>
            <AddEditHeader title="system" isAddMode={isAddMode} />
            <FormGroup>
              <FormInput name="title" title="Tittel" type="text" errors={errors.title} touched={touched.title} value={values.title} />
              <FormInput name="description" title="Beskrivelse" type="text" errors={errors.description} touched={touched.description} value={values.description} />
              {hasAccess(user, Role.Admin) && (
                <FormSelect name="deviceId" title="Tilknyttet enhet" errors={errors.deviceId} touched={touched.deviceId} value={values.deviceId}>
                  {devices &&
                  devices.map((device) => (
                    <option key={device.id} value={device.id}>
                      {device.title}
                    </option>
                  ))}
                </FormSelect>
              )} 
              <FormSubmit />
            </FormGroup>
          </FormContainer>
        );
      }}
    </Formik>
  );
}

export { AddEditSystem };
