import { Link } from 'react-router-dom';
import { classNames } from 'utils/classNames';

/**
 *
 * @param {Object} props { to: href, children: text, fill: boolean }
 * @returns
 */
export function LinkButton({ to, children, fill, className }) {
  return (
    <Link
      className={`${className}
        ${fill ? 'transition-all w-full h-full flex justify-center items-center py-[5px] rounded-full bg-gray-800 text-white border-2 border-gray-800 hover:bg-gray-600 hover:border-gray-600' : 'py-[5px] transition-all w-full h-full flex justify-center items-center rounded-full border-2 border-gray-800 text-black hover:bg-gray-800 hover:text-white'}
        `}
      to={to}
    >
      {children}
    </Link>
  );
}

export function SubmitButton({ isSubmitting, children, className }) {
  return (
    <div className="form-element">
      <button
        type="submit"
        disabled={isSubmitting}
        className={`${className} w-full h-full flex py-1 justify-center items-center rounded-full bg-gray-800 border-2 border-gray-800 hover:border-gray-600 text-white hover:bg-gray-600`}
      >
        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
        {children}
      </button>
    </div>
  );
}

export function Button({ children, className, ...rest }) {
  return (
    <button
      type="button"
      className={classNames(
        'text-white hover:bg-gray-700 hover:text-white bg-gray-500',
        'px-3 py-2 rounded-md text-sm font-medium',
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
}

export function PageButton({ children, className, disabled, ...rest }) {
  return (
    <button
      type="button"
      className={classNames(
        'relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 ',
        className,
        disabled ? ' cursor-default' : ' hover:bg-gray-100 cursor-pointer'
      )}
      {...rest}
    >
      {children}
    </button>
  );
}
