import ContactIcon from './icons/contact';
import DashboardIcon from './icons/dashboard';
import LocationIcon from './icons/location';
import OverviewIcon from 'screens/dashboard/icons/overview';
import AlarmIcon from 'screens/dashboard/icons/alarm';
import { Role } from 'utils';

export const adminNavigation = [
  { name: 'Oversikt', href: '/dashboard/', icon: <DashboardIcon /> },
  { name: 'Kunder', href: '/dashboard/customers', icon: <LocationIcon /> },
  { name: 'Enheter', href: '/dashboard/devices' },
  { name: 'Brukere', href: '/dashboard/users' },
  { name: 'Meldinger', href: '/dashboard/message', icon: <ContactIcon /> },
  { name: 'SystemBygger', href: '/dashboard/canvas' },
];

export const userNavigation = [
  { name: 'Oversikt', href: '/dashboard', icon: <OverviewIcon /> },
  { name: 'Lokasjoner', href: '/dashboard/locations', icon: <LocationIcon /> },
  { name: 'Alarmer', href: '/dashboard/alarms', icon: <AlarmIcon /> },
  { name: 'Kontakt', href: '/dashboard/contact', icon: <ContactIcon /> },
  { name: 'Brukere', href: '/dashboard/users', icon: <ContactIcon />, role: Role.CustomerAdmin },
];