import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { accountService } from 'services';
import toast from 'react-hot-toast';

function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();

  const TokenStatus = {
    Validating: 'Validating',
    Valid: 'Valid',
    Invalid: 'Invalid',
  };

  const [token, setToken] = useState(null);
  const [tokenStatus, setTokenStatus] = useState(TokenStatus.Validating);

  useEffect(() => {
    //const { token } = queryString.parse(location.search);
    const { token } = location.search;

    // remove token from url to prevent http referer leakage
    navigate(location.pathname);

    accountService
      .validateResetToken(token)
      .then(() => {
        setToken(token);
        setTokenStatus(TokenStatus.Valid);
      })
      .catch(() => {
        setTokenStatus(TokenStatus.Invalid);
      });
  }, []);

  function getForm() {
    const initialValues = {
      password: '',
      confirmPassword: '',
    };

    const validationSchema = Yup.object().shape({
      password: Yup.string().min(6, 'Bruk seks eller flere tegn').required('Skriv inn passord'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passordene samsvarer ikke')
        .required('Skriv inn passord'),
    });

    function onSubmit({ password, confirmPassword }, { setSubmitting }) {
      accountService
        .resetPassword({ token, password, confirmPassword })
        .then(() => {
          toast.success('Passordet er tilbakestilt. Du kan nå logge inn med ditt nye passord.');
          navigate('login');
        })
        .catch((error) => {
          setSubmitting(false);
          toast.error(error);
        });
    }

    return (
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div className="form-group">
              <label>Passord</label>
              <Field
                name="password"
                type="password"
                className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
              />
              <ErrorMessage name="password" component="div" className="invalid-feedback" />
            </div>
            <div className="form-group">
              <label>Gjenta passord</label>
              <Field
                name="confirmPassword"
                type="password"
                className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')}
              />
              <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
            </div>
            <div className="form-row">
              <div className="form-group col">
                <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                  {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                  Tilbakestill passord
                </button>
                <Link to="login" className="btn btn-link">
                  Avbryt
                </Link>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  function getBody() {
    switch (tokenStatus) {
    case TokenStatus.Valid:
      return getForm();
    case TokenStatus.Invalid:
      return (
        <div>
            Token validation failed, if the token has expired you can get a new one at the{' '}
          <Link to="forgot-password">forgot password</Link> page.
        </div>
      );
    case TokenStatus.Validating:
      return <div>Validating token...</div>;
    }
  }

  return (
    <div>
      <h3 className="card-header">Tilbakestill passord</h3>
      <div className="card-body">{getBody()}</div>
    </div>
  );
}

export { ResetPassword };
