const { useState } = require('react');
const { deviceService } = require('services');

export function SensorOptionInput({ id, initialValue, option, isText }) {
  const [inputValue, setInputValue] = useState(initialValue !== null ? initialValue : undefined);
  
  function updateSensor(e) {
    const value = e.target.value;
    setInputValue(value);
    if (!value) return;
    deviceService.updateSensor(id, { [option]: value });
  }
  
  return (
    <input className='rounded-md p-1 w-18' type={isText ? 'text' : 'number'} onChange={updateSensor} value={inputValue} />
  );
}