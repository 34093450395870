import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver && 'lightblue',
  padding: grid - 2,
  borderRadius: '10px',
});

export function CardGrid({ state, setState, columns = 6, children, canEdit = true }) {
  function onDragEnd(result) {
    const { source, destination } = result;
    
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setState(newState);
    }
  }
  return (
    <div className={`grid grid-cols-2 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-${columns}`}>
      <DragDropContext onDragEnd={onDragEnd}>
        {state &&
            state.map((col, column) => (
              <Droppable key={column} droppableId={`${column}`}>
                {(provided, snapshot) => (
                  <div
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="span-1 flex flex-col items-center"
                  >
                    {col.length > 0 &&
                      col.map((card, row) => (
                        <Draggable
                          key={state.indexOf(col) + row}
                          draggableId={state.indexOf(col).toString() + row.toString()}
                          index={row}
                          isDragDisabled={canEdit ? false : true}
                        >
                          {(provided) => (
                            <div
                              className='w-full h-full'
                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {children({ card: card, row: row, column: column })}
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
      </DragDropContext>
    </div>
  );
}
