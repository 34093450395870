import { sensorService } from 'services';
import { useSystemContext } from '../../context';
import { DefaultInput } from 'components/Elements/Input';
import { useEffect, useState } from 'react';

export default function SensorInterval() {
  const { selected, system, setLocalSystem, sensor } = useSystemContext();
  const [state, setState] = useState(0);

  useEffect(() => {
    setState(sensor?.interval || 0);
  }, [sensor]);

  function changeInterval(e) {
    setState(e.target.value);
    // const res = { ...system };
    // res.panels.filter((x) => x.position === selected)[0].sensor.interval = e.target.value;
    // setLocalSystem(res);

    sensorService.update(sensor.id, {
      interval: e.target.value
    });
  }

  return (
    <div className="">
      <p className="font-semibold">Intervall</p>
      <form>
        <DefaultInput
          onChange={changeInterval}
          placeholder={sensor.interval}
          type="number"
          value={state}
          // className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </form>
    </div>
  );
}