import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';


const DashboardContext = createContext({
  path: null,
  setPath: () => {},
  pathname: null,
  setPathName: () => {}
});

export function DashboardContainer({ children }) {
  const [pathname, setPathname] = useState([]);
  const [path, setPath] = useState([]);

  const state = useMemo(() => {
    const s = {
      pathname,
      setPathname,
      path,
      setPath
    };

    return s;
  }, [path, setPath, pathname, setPathname]);

  return (
    <DashboardContext.Provider value={state}>
      {children}
    </DashboardContext.Provider>
  );
}

export function useDashboardContext() {
  return useContext(DashboardContext);
}