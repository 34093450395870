export const defaultArrow = {
  r: 90,
  x: 150,
  y: 150,
  w: 40,
  h: 21,
  minW: 10,
  minH: 10,
  type: 'arrow',
  style: {
    fill: 'black',
    strokeWidth: 8,
  },
  render: Arrow,
};

export function Arrow({ id, state, selected, view }) {
  const position = {
    l: { x: state.x - state.w / 2, y: state.y },
    r: { x: state.x + state.w / 2, y: state.y },
    a: { x: state.x - state.w / 2 + (state.w * 3) / 4, y: state.y },
    t: { x: state.x - state.w / 2 + (state.w * 3) / 4, y: state.y - state.h / 3 },
    b: { x: state.x - state.w / 2 + (state.w * 3) / 4, y: state.y + state.h / 3 },
  };

  return (
    <>
      {position && position.l.x && (
        <g cursor={view ? 'default' : 'pointer'}>
          <line
            id={id}
            className="item"
            x1={position.l.x}
            y1={position.l.y}
            x2={position.a.x}
            y2={position.a.y}
            stroke={state.style.fill}
            strokeWidth={state.h / 5}
            transform={`rotate(${state.r} ${state.x} ${state.y})`}
          />
          <polygon
            id={id}
            className="item"
            points={`${position.t.x},${position.t.y} ${position.r.x},${position.r.y} ${position.b.x},${position.b.y}`}
            fill={state.style.fill}
            stroke={selected === id ? '#77c9f2' : 'none'}
            strokeDasharray="15, 15"
            strokeWidth={3}
            pointerEvents="bounding-box"
            transform={`rotate(${state.r} ${state.x} ${state.y})`}
          ></polygon>
        </g>
      )}
    </>
  );
}
