import config from 'config';
import { fetchWrapper } from 'utils';

const baseUrl = `${config.apiUrl}/dashboards`;

export const dashboardService = {
  getById,
  getAll,
  add,
  update,
  remove,
};

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function getAll() {
  return fetchWrapper.get(`${baseUrl}/`);
}

function add() {
  return fetchWrapper.post(`${baseUrl}/`);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params);
}

function remove(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}
