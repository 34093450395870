import { useMemo, useState, useEffect } from 'react';
import { customerService } from 'services';
import Table, { EditButton, StatusPill } from './Table';
import { ContentContainer } from 'components/Containers/ContentContainer';
import { useNavigate } from 'react-router';
import { Loading } from '../Loading';
import { GetCustomerStatus } from 'utils/status';

export function CustomerTable({ clickPrefix }) {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState(null);

  const customerColumns = useMemo(
    () => [
      {
        Header: 'Kunde',
        accessor: 'orgName',
        cardClassName: 'text-[4vw] sm:text-xl font-bold',
      },
      {
        Header: 'Lokasjoner',
        accessor: 'locations.length',
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ value }) => {
          return EditButton({ value, link: 'customers' });
        },
        hideOnCard: true,
      },
      {
        Header: 'Status',
        accessor: 'id',
        id: 'Status',
        Cell: StatusPill,
        hideOnCard: true,
      },
    ],
    []
  );

  useEffect(() => {
    customerService.getAll().then((x) => setCustomers(x));
  }, []);

  function removeCustomer(customerId) {
    setCustomers(
      customers.map((x) => {
        if (x.id === customerId) {
          x.isDeleting = true;
        }
        return x;
      })
    );
    customerService.delete(customerId).then(() => {
      setCustomers((customers) => customers.filter((x) => x.id !== customerId));
    });
  }

  function customerClick(object) {
    navigate((clickPrefix ? `${clickPrefix}` : '') + `${object.id}`);
  }

  function addClick() {
    navigate('/dashbord/kunder/add');
  }

  return (
    <ContentContainer>
      <div className="text-center mb-4">
        <h4>Kunder</h4>
        <p>Antall kunder: {customers && customers.length}</p>
      </div>
      {customers ? <Table columns={customerColumns} statusFunction={GetCustomerStatus} data={customers} handleClick={customerClick} addButton={addClick} /> : <Loading />}
    </ContentContainer>
  );
}
