import ContactIcon from './icons/contact';
import DashboardIcon from './icons/dashboard';
import LocationIcon from './icons/location';
import OverviewIcon from 'screens/dashboard/icons/overview';
import AlarmIcon from 'screens/dashboard/icons/alarm';
import UsersIcon from './icons/users';
import DevicesIcon from './icons/devices';
import SystemBuilderIcon from './icons/systemBuilder';
import CustomersIcon from './icons/customers';
import { UserIcon } from 'components/icons';
import { Role } from 'utils';
import { Overview } from './Overview';
import { Customers } from './Customers';
import { Devices } from './Devices';
import { Users } from './Users';
import { Message } from './Message';
import { SystemBuilder } from './SystemBuilder';
import { AdminOverview } from './Overview/AdminOverview';
import { Alarms } from './Alarmlog';
import { Locations } from './Locations';
import { Contact } from './Contact';
import { MyDashboard } from './MyDashboard';
import { UpdateProfile } from 'screens/profile/Profile';
import { ProfileNotifications } from 'screens/profile';
import { NotFoundPage } from 'screens/ErrorScreens';

const baseurl = '/dashbord';
const profileUrl = '/profil';

export const routes = [
  { 
    id: 'userOverview',
    name: 'Oversikt', 
    path: '', 
    href: baseurl,
    icon: <OverviewIcon />, 
    access: Role.User,
    noAccess: Role.Admin,
    element: Overview
  },
  { 
    id: 'adminOverview',
    name: 'Oversikt', 
    path: '',
    href: baseurl, 
    icon: <OverviewIcon />, 
    access: Role.Admin,
    element: AdminOverview
  },
  { 
    name: 'Kunder', 
    path: 'kunder/*',
    href: baseurl + '/kunder',
    icon: <CustomersIcon />,
    access: Role.Admin,
    element: Customers
  },
  {
    name: 'Enheter', 
    path: 'enheter/*', 
    href: baseurl + '/enheter',
    icon: <DevicesIcon />,
    access: Role.Admin,
    element: Devices
  },
  { 
    name: 'Brukere', 
    path: 'brukere/*', 
    href: baseurl + '/brukere',
    icon: <UsersIcon />,
    access: Role.CustomerAdmin,
    element: Users
  },
  { 
    name: 'Meldinger', 
    path: 'meldinger', 
    href: baseurl + '/meldinger',
    icon: <ContactIcon />, 
    access: Role.Admin,
    element: Message
  },
  { 
    name: 'System bygger', 
    path: 'canvas/*',
    href: baseurl + '/canvas',
    icon: <SystemBuilderIcon />,
    access: Role.Admin,
    element: SystemBuilder
  },
  {
    name: 'Lokasjoner',
    path: 'lokasjoner/*', 
    href: baseurl + '/lokasjoner',
    icon: <LocationIcon />,
    access: Role.User,
    noAccess: Role.Admin,
    element: Locations
  },
  { 
    name: 'Alarmer', 
    path: 'alarmer', 
    href: baseurl + '/alarmer',
    icon: <AlarmIcon />,
    access: Role.User,
    noAccess: Role.Admin,
    element: Alarms
  },
  { 
    name: 'Kontakt', 
    path: 'kontakt', 
    href: baseurl + '/kontakt',
    icon: <ContactIcon />,
    access: Role.User,
    noAccess: Role.Admin,
    element: Contact
  },
  { 
    name: 'Mine dashbord', 
    path: 'mine-dashbord/*', 
    href: baseurl + '/mine-dashbord',
    icon: <DashboardIcon />,
    access: Role.User,
    noAccess: Role.Admin,
    element: MyDashboard,
  },
  {
    path: '*',
    access: Role.User,
    element: NotFoundPage
  }
];

export const profileRoutes = [
  { 
    name: 'Min profil', 
    path: '', 
    href: profileUrl,
    icon: <UserIcon />, 
    access: Role.User,
    element: UpdateProfile
  },
  { 
    name: 'Varsler', 
    path: 'varsler',
    href: profileUrl + '/varsler', 
    icon: <AlarmIcon />, 
    access: Role.User,
    element: ProfileNotifications
  },
  {
    path: '*',
    access: Role.user,
    element: NotFoundPage
  }
];