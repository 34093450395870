export const defaultText = {
  r: 0,
  x: 150,
  y: 150,
  type: 'text',
  text: 'Text',
  fontSize: 50,
  w: 100,
  h: 50,
  render: Text,
};

export function Text({ id, state, view }) {
  return (
    <g
      // stroke={selected === id ? "#77c9f2" : "none"}
      // strokeDasharray="15, 15"
      // strokeWidth={3}
      cursor={view ? 'default' : !state.disabled ? 'move' : 'pointer'}
    >
      <text
        id={id}
        className="item"
        cursor={view ? 'default' : 'pointer'}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={state.fontSize}
        //fontSize={state.w / 5}
        x={state.x}
        y={state.y}
      >
        {state.text}
      </text>
    </g>
  );
}
