import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormGroup, FormContainer, FormInput, FormSubmit } from 'components/Elements/forms';
import { accountService } from 'services';
import toast from 'react-hot-toast';
import { DashboardWrapper } from '../components/DashboardWrapper';
import { Widget } from '../components/Widget';

function Contact() {
  const user = accountService.userValue;

  const initialValues = {
    name: user ? user.firstName + ' ' + user.lastName : '',
    from: user ? user.email : '',
    message: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Skriv inn navn'),
    from: Yup.string().email('Ugyldig e-postadresse').required('Skriv inn e-post'),
    message: Yup.string()
      .min(6, 'Melding må være minst 6 tegn')
      .max(500, 'Melding må være under 500 tegn')
      .required('Skriv inn melding'),
  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    accountService
      .sendEmail(fields)
      .then(() => {
        setSubmitting(false);
        toast.success('E-post sendt. Kopi sendt til ' + fields.from);
      })
      .catch((error) => {
        setSubmitting(false);
        toast.error(error);
      });
  }
  return (
    // <DashboardWrapper title="Kontakt" path={['dashboard','contact']}>
    <Widget>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ errors, touched, values }) => (
          <FormGroup>
            <FormContainer clean>
              <p className='mb-2'>Har du problemer, eller ønsker annen support, ta kontakt med oss her:</p>
              <FormInput name="name" title="Navn" type="text" errors={errors.name} touched={touched.name} value={values.name} />
              <FormInput name="from" title="E-post" type="text" errors={errors.from} touched={touched.from} value={values.from} />
              <FormInput name="message" title="Melding" type="textarea" errors={errors.message} touched={touched.message} value={values.message} />
              <FormSubmit />
            </FormContainer>
          </FormGroup>
        )}
      </Formik>
    </Widget>
    // </DashboardWrapper>
  );
}

export { Contact };
