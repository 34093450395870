import { accountService } from 'services';
import { Editor } from './editor';
import { Role, hasAccess } from 'utils';
import { SensorModal } from './SensorModal';
import { InfoModal } from './InfoModal';
import { useSystemContext } from './context';
import { Transformable } from 'components/Modules/transformable';
import { useAppContext } from 'components/Context/AppWrapper';
import { DashboardWrapper } from 'screens/dashboard/components/DashboardWrapper';
import { routes } from 'screens/dashboard/routes';
import { Widget } from 'screens/dashboard/components/Widget';
import { SensorSampler } from './sensor/SensorSampler';
import { DeviceStatusEnum } from 'utils/status';


function System() {
  const { system, edit, setEdit, selected, setSelected } = useSystemContext();
  const user = accountService.userValue;
  const { status: fetchStatus } = useAppContext();
  const statusObject = fetchStatus?.devices?.find(x => x.id === system?.deviceId);
  const error = statusObject ? statusObject?.status === DeviceStatusEnum.Error : false;
  const offline = statusObject ? statusObject?.status === DeviceStatusEnum.Offline : false;
  const activeRoutes = [];
  routes.map(route => {
    if(route.name && route.href){
      if (route.noAccess) {
        if (!hasAccess(user, route.noAccess)) {
          activeRoutes.push(route);
        }
      } else {
        if (hasAccess(user, route.access)) {
          activeRoutes.push(route);
        }
      }
    }
  });

  if(!system) {
    return (
      <div colSpan="4" className="flex flex-col justify-center min-h-screen/2 overflow-x-hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-12 w-12 animate-pulse text-blue-500 place-self-center"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z"
          />
        </svg>
      </div>
    );
  }

  return (
    <DashboardWrapper hidePath menuItems={activeRoutes}>
      <Widget>
        <div className='overflow-x-hidden'>
          <div className="flex justify-center p-2 relative">
            {user && hasAccess(user, Role.Admin) && (
              <div className="absolute top-0 left-0">
                <button
                  className={
                    'border-2 border-blue-500 hover:bg-blue-500 font-bold py-1 px-4 rounded-full ml-2' +
                  (edit && 'bg-blue-500')
                  }
                  onClick={() => setEdit()}
                >
                Edit
                </button>
              </div>
            )}
            <div className="flex flex-col mt-5 text-center">
              <p className="text-5xl font-medium">{system.title}</p>
              <h5 className='mt-2'>{system.description}</h5>
              <h5 className={`font-semibold text-xl ${!(error || offline) && 'text-green-600'} ${error && 'text-red-600'} ${offline && 'text-gray-400'}`}>
                {!(error || offline) && 'Drift'}
                {error && 'Systemfeil'}
                {offline && 'Frakoblet'}
              </h5>
            </div>
            <div className='absolute top-0 right-0'>
              <InfoModal />
            </div>
          </div>
          {user && hasAccess(user, Role.Admin) && edit && (
            <Editor />
          )}
          {!edit && <SensorModal />}
          <Transformable.View 
            items={system.canvas?.items} 
            panels={system.panels} 
            edit={edit} 
            selected={selected} 
            setSelected={setSelected}
          >
            {({ position, g, polygon, text }) => (
              <SensorSampler position={position} g={g} polygon={polygon} text={text} />
            )}
          </Transformable.View>
        </div>
      </Widget>
    </DashboardWrapper>
  );
}

export { System };
