import { useState } from 'react';
import Select from 'react-select';

function Selector({ data, group, reset, className, placeholder, save, defaultValue }) {
  if (group && data) {
    const temp = [];
    data.map((elem) => temp.push({ label: elem.title, options: elem.data }));
    data = temp;
  }

  // set value for default selection
  const [selectedValue, setSelectedValue] = useState();

  // handle onChange event of the dropdown
  const handleChange = (e) => {
    if (reset) {
      setSelectedValue(null);
    }
    save(e);
  };

  return (
    <div className={`${className}`}>
      <Select
        className=""
        placeholder={placeholder ? placeholder : 'Velg'}
        value={selectedValue && selectedValue} // set selected values
        options={data && data} // set list of names
        onChange={handleChange} // assign onChange function
        isClearable
        isSearchable
      />
    </div>
  );
}

export { Selector };
