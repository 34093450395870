import { useMemo, useState, useEffect } from 'react';
import { customerService, accountService } from 'services';
import Table, { DatePill, StatusPill, EditButton } from './Table';
import { ContentContainer } from 'components/Containers/ContentContainer';
import { useNavigate, useParams } from 'react-router';
import { Loading } from '../Loading';
import { Role, hasAccess } from 'utils';
import { GetSystemStatus } from 'utils/status';

export function SystemTable({ cardsAsDefault }) {
  const { locationId } = useParams();
  let { customerId } = useParams();
  const navigate = useNavigate();
  if (!customerId) {
    customerId = customerService.customerValue.id;
  }
  const [systems, setSystems] = useState(null);
  const [locations, setLocations] = useState(null);
  const user = accountService.userValue;

  useEffect(() => {
    locations && locations.length > 1 && (
      GetSystemStatus(locations[0].deviceId)
    );
  }, [locations]);

  const systemColumns = useMemo(
    () => [
      {
        Header: 'Tittel',
        accessor: 'title',
        cardClassName: 'text-[4vw] sm:text-xl font-bold',
      },
      {
        Header: 'Beskrivelse',
        accessor: 'description',
        cardClassName: 'text-[4vw] sm:text-xl italic',
      },
      {
        Header: 'Sist endret',
        accessor: 'updatedAt',
        Cell: DatePill,
        hideOnCard: true,
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: 'id',
        Cell: StatusPill,
        hideOnCard: true,
      },
      {
        Header: ' ',
        accessor: 'id',
        Cell: ({ value }) => {
          return EditButton({ value });
        },
        hideOnCard: true,
        access: Role.Editor
      },
      // {
      //   Header: " ",
      //   accessor: "id",
      //   Cell: ({ value }) => { return EditButton({ value, link:systemId})},
      // }
    ],
    []
  );

  useEffect(() => {
    customerService.getById(customerId).then((x) => {
      setSystems(
        x.locations.filter((elem) => elem.id === parseInt(locationId))[0]
          ? x.locations.filter((elem) => elem.id === parseInt(locationId))[0].systems
          : []
      );
    });
  }, []);

  function removeLocation(locationId) {
    setLocations(
      locations.map((x) => {
        if (x.id === locationId) {
          x.isDeleting = true;
        }
        return x;
      })
    );
    customerService.removeLocation(locationId).then(() => {
      setLocations((locations) => locations.filter((x) => x.id !== locationId));
    });
  }

  function systemClick(object) {
    // console.log(object.id);
    navigate(`/system/${object.id}`);
  }

  function addClick() {
    navigate('add');
  }
  
  return (
    <ContentContainer>
      <div className="text-center mb-4">
        <h4>Systemer</h4>
        <p>Antall systemer: {systems && systems.length}</p>
      </div>
      {systems ? (
        <Table columns={systemColumns} cardsAsDefault={cardsAsDefault} statusFunction={GetSystemStatus} data={systems} handleClick={systemClick} addButton={hasAccess(user, Role.Editor) ? addClick : false} />
      ) : (
        <Loading />
      )}
    </ContentContainer>
  );
}
