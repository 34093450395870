export const defaultCurvedArrow = {
  r: 0,
  x: 150,
  y: 150,
  w: 80,
  h: 100,
  type: 'curvedArrow',
  style: {
    fill: 'black',
  },
  render: CurvedArrow,
};

export function CurvedArrow({ id, state, selected, view }) {
  const position = {
    l: { x: state.x - state.w / 2, y: state.y - state.h / 2 + 30 },
    r: { x: state.x + state.w / 2, y: state.y - state.h / 2 + 29 },
    ml: { x: state.x - (state.w * 5) / 12, y: state.y + state.h / 2 },
    mr: { x: state.x + (state.w * 5) / 12, y: state.y + state.h / 2 },
    al: { x: state.x + (state.w * 4) / 12, y: state.y - state.h / 2 + 30 },
    ar: { x: state.x + state.w / 2 + (state.w * 2) / 12, y: state.y - state.h / 2 + 30 },
    at: { x: state.x + state.w / 2, y: state.y - state.h / 2 },
  };

  return (
    <>
      {position && position.at.x && (
        <g cursor={view ? 'default' : 'pointer'}>
          <path
            id={id}
            className="item"
            d={`M ${position.l.x} ${position.l.y} C ${position.ml.x} ${position.ml.y} ${position.mr.x} ${position.mr.y} ${position.r.x} ${position.r.y} `}
            stroke="black"
            strokeWidth={state.w / 12}
            fill="transparent"
            transform={`rotate(${state.r} ${state.x} ${state.y})`}
          />
          <polygon
            id={id}
            className="item"
            points={`${position.al.x},${position.al.y} ${position.at.x},${position.at.y} ${position.ar.x},${position.ar.y}`}
            fill={state.style.fill}
            stroke={selected === id ? '#77c9f2' : 'none'}
            strokeDasharray="15, 15"
            strokeWidth={3}
            pointerEvents="bounding-box"
            transform={`rotate(${state.r} ${state.x} ${state.y})`}
          ></polygon>
        </g>
      )}
    </>
  );
}
