/* eslint-disable no-constant-condition */

export const defaultSensor = {
  r: 0,
  x: 150,
  y: 150,
  w: 50,
  h: 30,
  minH: 10,
  minW: 20,
  type: 'sensor',
  key: 'sensor',
  render: Sensor,
};

export function Sensor({ id, state, children }) {
  const position = {
    lt: { x: state.x - state.w / 2, y: state.y - state.h / 2 },
    rt: { x: state.x + state.w / 2, y: state.y - state.h / 2 },
    rb: { x: state.x + state.w / 2, y: state.y + state.h / 2 },
    lb: { x: state.x - state.w / 2, y: state.y + state.h / 2 },
  };

  const g = {
    cursor: 'pointer',
    className: 'item',
    id: id,
  };

  const polygon = {
    id: id,
    className: 'item',
    pointerEvents: 'bounding-box',
    points: `${position.lt.x},${position.lt.y} ${position.rt.x},${position.rt.y} ${position.rb.x},${position.rb.y} ${position.lb.x},${position.lb.y}`,
    strokeWidth: 10,
    strokeLinejoin: 'round',
    rx: '50',
    transform: `rotate(${state.r} ${state.x} ${state.y})`,
    fill: '#e6e6e6',
    stroke: '#e6e6e6'
  };

  const text = {
    id: id,
    className: 'item',
    fontSize: Math.min(state.w / 2, state.h / 2),
    textAnchor: 'middle',
    dominantBaseline: 'central',
    x: state.x,
    y: state.y - 1,
  };

  if (position && position.lt.x) {
    if (children) {
      return (
        children({ position: id, g: g, polygon: polygon, text: text })
      );
    }
    return (
      <>
        <g
          {...g}
        >
          <polygon
            {...polygon}
          />
          <text
            {...text}
          >
            val
          </text>
        </g>
      </>
    );
  }
}

export function SensorPropsHooks({ id, state, selected, view, setSelected, sensorId, edit, sample, children }) {
  const position = {
    lt: { x: state.x - state.w / 2, y: state.y - state.h / 2 },
    rt: { x: state.x + state.w / 2, y: state.y - state.h / 2 },
    rb: { x: state.x + state.w / 2, y: state.y + state.h / 2 },
    lb: { x: state.x - state.w / 2, y: state.y + state.h / 2 },
  };

  return (
    <>
      {position && position.lt.x && (
        <>
          <g
            cursor="pointer"
            className="item"
            id={id}
            onPointerUp={view && (edit ? () => setSelected(id) : (e) => setSelected(sensorId))}
          >
            <polygon
              id={id}
              className="item"
              pointerEvents="bounding-box"
              points={`${position.lt.x},${position.lt.y} ${position.rt.x},${position.rt.y} ${position.rb.x},${position.rb.y} ${position.lb.x},${position.lb.y}`}
              stroke={selected === id ? '#77c9f2' : sample ? (!sample.status ? '#e6e6e6' : 'red') : '#e6e6e6'}
              strokeDasharray={selected === id ? (!view ? '15, 15' : '0') : '0'}
              strokeWidth={selected === id ? (view ? 10 : 3) : 10}
              strokeLinejoin="round"
              rx="50"
              fill={selected === id && view ? '#77c9f2' : sample ? (!sample.status ? '#e6e6e6' : 'red') : '#e6e6e6'}
              transform={`rotate(${state.r} ${state.x} ${state.y})`}
            />
            <text
              id={id}
              className="item"
              fontSize={Math.min(state.w / 2, state.h / 2)}
              textAnchor="middle"
              dominantBaseline="central"
              x={state.x}
              y={state.y - 1}
            >
              {/* {edit ? sensorId : sample ? Number.parseFloat(sample.value).toPrecision(3) : 'val'} */}
              {/* {children({ sensor: { id: 1, offset: 0, invert: false }})} */}
              val
            </text>
          </g>
        </>
      )}
    </>
  );
}

export function SensorNoHooks2({ id, state, selected, view, setSelected, sensor, edit }) {
  const position = {
    lt: { x: state.x - state.w / 2, y: state.y - state.h / 2 },
    rt: { x: state.x + state.w / 2, y: state.y - state.h / 2 },
    rb: { x: state.x + state.w / 2, y: state.y + state.h / 2 },
    lb: { x: state.x - state.w / 2, y: state.y + state.h / 2 },
  };

  return (
    <>
      {position && position.lt.x && (
        <>
          <g
            cursor="pointer"
            className="item"
            id={id}
            onPointerUp={view && (edit ? () => setSelected(id) : (e) => setSelected(sensor))}
          >
            <polygon
              id={id}
              className="item"
              points={`${position.lt.x},${position.lt.y} ${position.rt.x},${position.rt.y} ${position.rb.x},${position.rb.y} ${position.lb.x},${position.lb.y}`}
              stroke={selected === id ? '#77c9f2' : false ? (true ? '#e6e6e6' : 'red') : '#e6e6e6'}
              strokeDasharray={selected === id ? (!view ? '15, 15' : '0') : '0'}
              strokeWidth={selected === id ? (view ? 10 : 3) : 10}
              strokeLinejoin="round"
              rx="50"
              fill={selected === id && view ? '#77c9f2' : false ? (true ? '#e6e6e6' : 'red') : '#e6e6e6'}
              transform={`rotate(${state.r} ${state.x} ${state.y})`}
            />
            <text
              id={id}
              className="item"
              fontSize={Math.min(state.w / 2, state.h / 2.1)}
              focusable={false}
              textAnchor="middle"
              dominantBaseline="central"
              x={state.x}
              y={state.y - 1}
            >
              val
            </text>
          </g>
        </>
      )}
    </>
  );
}
