import { Form, Formik } from 'formik';

export default function ImagePicker({ onSubmit }) {
  return (
    <Formik initialValues={{ file: null }} onSubmit={onSubmit}>
      {({ setFieldValue, values }) => (
        <Form className="h-full">
          {!values['file'] ? (
            <label className="h-full w-full flex flex-col justify-center text-center px-2 py-4 bg-white rounded-md shadow-md tracking-wide border border-blue cursor-pointer hover:bg-purple-600 hover:text-white text-purple-600 ease-linear transition-all duration-150">
              <span className="text-base leading-normal">Legg til bilde</span>
              <input
                className="hidden"
                type="file"
                name="file"
                onChange={(e) => setFieldValue('file', e.target.files[0])}
              />
            </label>
          ) : (
            <div className="h-full w-full flex flex-col px-2 rounded-md shadow-md tracking-wide border border-blue cursor-pointer ease-linear transition-all duration-150 justify-end text-center relative">
              <img
                alt="UploadedImage"
                src={URL.createObjectURL(values['file'])}
                className="absolute left-0 top-0 h-full w-full object-cover rounded"
              />
              <div className="relative">
                {/* <p>{values["file"].name}</p> */}
                <div className="flex justify-center space-x-1">
                  <button
                    type="submit"
                    className="py-1 bg-green-500 bg-opacity-70 px-3 rounded-full hover:bg-opacity-100 mb-1"
                  >
                                  Last opp
                  </button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8 hover:text-red-500 cursor-pointer rounded-full bg-white bg-opacity-70"
                    onClick={() => setFieldValue('file', null)}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}