import { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { accountService } from 'services';
import { Login } from './Login';
import { VerifyEmail } from './VerifyEmail';
import { ForgotPassword } from './ForgotPassword';
import { ResetPassword } from './ResetPassword';

function Logout() {
  accountService.logout();
  return <Navigate to="/account/login" />;
}

function Account() {
  const navigate = useNavigate();

  useEffect(() => {
    // redirect to home if already logged in
    if (accountService.userValue) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <div className="grid place-items-center">
      <div className="w-10/12 sm:w-8/12 md:w-7/12 lg:w-5/12">
        <div className="mt-10 bg-gray-50 p-10 rounded-xl w-full shadow">
          <Routes>
            <Route path="login" element={<Login />}></Route>
            <Route path="logout" element={<Logout />}></Route>
            <Route path="verify-email" element={<VerifyEmail />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export { Account };
