import { ViewWrapper } from 'components/Containers/ViewWrapper';
import { sensorService } from 'services';
import toast from 'react-hot-toast';
import { ToggleSwitch } from 'components/Modules/ToggleSwitch';

function NotificationSwitches({ row }) {
  const { id, subscription } = row.original;
  function subscribeTo(sensorId, { email, sms }) {
    sensorService.subscribeTo(sensorId, email, sms).catch(() => toast.error('Cannot subscribe!'));
  }

  return (
    <div className="flex space-x-2">
      <ToggleSwitch
        initialValue={subscription?.email}
        onClick={(val) => subscribeTo(id, { email: val, sms: null })}
        title="Epost"
      />
      <ToggleSwitch
        initialValue={subscription?.sms}
        onClick={(val) => subscribeTo(id, { email: null, sms: val })}
        title="SMS"
      />
    </div>
  );
}

export function SystemNotifications() {
  return (
    <div className="contain">
      <section id="details">
        <ViewWrapper>
          <div>
            <p className="text-gray-500">Vil du motta notifikasjoner hvis vi mister kontakt med et system? (Årsak kan variere fra strømbrudd til serverfeil)</p>
            <div className="flex space-x-5 my-3">
              <div className="flex flex-col justify-center">
                <p>Varsel ved system offline:</p>
              </div>
              <div className="flex space-x-2">
                <ToggleSwitch
                  initialValue={false}
                  // onClick={(val) => subscribeTo(id, { email: val, sms: null })}
                  title="Epost"
                />
                <ToggleSwitch
                  initialValue={false}
                  // onClick={(val) => subscribeTo(id, { email: null, sms: val })}
                  title="SMS"
                />
              </div>
            </div>
          </div>
        </ViewWrapper>
      </section>
    </div>
  );
}
