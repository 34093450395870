import { DeviceStatusEnum, SensorStatusEnum } from './status';

export function statusEscalate(statusSource){
  if(statusSource.sensorStatus === SensorStatusEnum.Ok && statusSource.deviceStatus === DeviceStatusEnum.Online) 
    return({title:'Drift', style: 'bg-green-100 text-green-800'});
  if(statusSource.sensorStatus === SensorStatusEnum.Error || statusSource.deviceStatus === DeviceStatusEnum.Error)
    return({title: 'Alarm', style: 'bg-red-100 text-red-800'});
  if(statusSource.deviceStatus === DeviceStatusEnum.Offline)
    return({title: 'Offline', style: 'bg-gray-100 text-gray-700'});
}
