export function translate(value, sensor, invert = true) {
  if(!value && value !== 0) return null;
  const option = sensorOutputs.filter(elem => elem.id === sensor.output)[0];
  const output = option ? option.compute(value) : value;
  const res = (output + sensor.offset) * (invert && sensor.invert ? -1 : 1);
  if (!res) return 0.0;
  return res.toFixed(option ? option.precision : 1);
}

export function rebate(value, sensor, invert = true) {
  if(!value && value !== 0) return null;
  const option = sensorOutputs.filter(elem => elem.id === sensor.output)[0];
  const input = option ? option.refute(value) : value;
  const res = input * (invert && sensor.invert ? -1 : 1) - sensor.offset;
  if (!res) return 0.0;
  return res.toFixed(option ? option.precision : 1);
}

export const sensorOutputs = [
  {
    id: 1,
    title: 'Raw',
    controller: '',
    compute: value => { return value; },
    refute: value => { return value; },
    precision: 1
  },
  { 
    id: 2,
    title: 'Klor',
    controller: 'mcp3208',
    compute: toClor,
    refute: fromClor,
    precision: 2,
    unit: 'PPM'
  },
  {
    id: 3,
    title: 'PH', 
    controller: 'mcp3208',
    compute: toPH,
    refute: fromPH,
    precision: 2,
    unit: 'pH'
  },
];

// function toTemperature(value) {
//   const res = -(Math.sqrt(-0.00232*( value / 3.3*998)/(1 - value /3.3) + 17.59246) - 3.908)/0.00116;
//   return res.toPrecision(4);
// }

// function fromTemperature(value) {
//   const res = (33 * (-1449997500 - 5666600*(value) + 841 * (value)**2))/(10 * (-2897097500 - 5666600*(value) + 841 * (value)**2));
//   return res.toPrecision(4);
// }

function toClor(value) {
  return 0.31 * value - 1.25;
  // return 0.31 * (4 + 16 * ((value - 745) / (3723 - 745)) - 1.24);
}

function fromClor(value) {
  return (value + 1.25) / 0.31;
  //return (((value / 0.31) + 1.24 - 4) / 16 * (3723 - 745)) + 745;
}

function toPH(value) {
  return 0.625 * value - 0.5;
}

function fromPH(value) {
  return (value + 0.5) / 0.625;
}