import { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CardGrid } from './CardGrid';
import { toast } from 'react-hot-toast';
import { customerService } from 'services/customer.service';
import { dashboardService } from 'services/dashboard.service';
import { Selector } from 'components/Modules/Selector';
import { accountService } from 'services';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {Card} from 'components/Modules/table/Card';
import { TrashIcon } from 'components/icons';
import { GetLocationStatus, GetSystemStatus } from 'utils/status';
import { useAppContext } from 'components/Context/AppWrapper';

export function GridController({ dashboardId, cards, preview = false, columns = 6, canEdit = true }) {
  const navigate = useNavigate();
  const user = accountService.userValue;
  const customer = customerService.customerValue;
  const [state, setState] = useState(null);
  const [options, setOptions] = useState(null);
  const {status: fetchStatus} = useAppContext();

  const getOptionList = useCallback(() => {
    const selectorArray = [];
    selectorArray[0] = { title: 'Locations', data: [] };
    selectorArray[1] = { title: 'Systems', data: [] };
    customer?.locations.map((location) =>
      selectorArray[0].data.push({
        value: location.id,
        label: location.title + ' - ' + location.address,
        locationId: location.id,
      })
    );
    customer?.locations.map((location) =>
      location.systems.map((system) =>
        selectorArray[1].data.push({
          value: system.id,
          label: system.title + ' - ' + system.description,
          systemId: system.id,
        })
      )
    );

    setOptions(selectorArray);
  }, [customer?.locations]);

  useEffect(() => {
    if (cards && cards.length > 0) {
      setState(cards);
    } else {
      const tmp = [];
      var i = 0;
      while (i < columns) {
        tmp.push([]);
        i++;
      }
      setState(tmp);
    }
    getOptionList();
  }, [cards, getOptionList, columns]);

  function handleChange(e) {
    // const previousState = state;
    // console.log(e);
    setState(e);
    dashboardService.update(dashboardId, { cards: e }).catch(error => {
      // setState(previousState);
      toast.error(error);
    });
  }

  function handleRemove(e, card) {
    handleChange(
      state.map((column) => {
        return column.filter((elem) => elem !== card);
      })
    );
  }

  function addCard(e) {
    const colIndex = state.indexOf(state.reduce((prev, next) => (prev.length > next.length ? next : prev)));
    const newState = [...state];
    newState[colIndex].push({ locationId: e.locationId, systemId: e.systemId });
    handleChange(newState);
  }

  function attachCardInfo(elem) {
    const systems = [];
    const locations = customer.locations;
    customer.locations.map((location) => {
      location.systems.map(system => {
        systems.push(system);
      });
    });
    const card =
      (elem.locationId && locations.filter((location) => location.id === elem.locationId)[0]) ||
      (elem.systemId &&
        systems.filter((system) => system.id === elem.systemId)[0]);
    return card;
  }

  function cardClick(card) {
    if (card.systemId) {
      navigate(`/system/${card.systemId}`);
    } else if (card.locationId) {
      navigate(`/dashbord/lokasjoner/${card.locationId}`);
    }
  }

  return (
    <div className="flex h-full">
      <div className="w-full">
        {!preview && (canEdit) && (
          <div className="w-full mb-5">
            <div className="flex w-full">
              <Selector
                group
                className="col-lg-5 w-full text-gray-800 outline-1 outline-sky-600"
                reset
                placeholder={'Legg til...'}
                data={options}
                save={addCard}
              ></Selector>

            </div>
          </div>
        )}
        <div className="absolute top-5 right-5">
          <Tooltip title="Fremvisning" onClickCapture={() => navigate(`/dashbord/mine-dashbord/${dashboardId}/preview`)}>
            <IconButton>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
              </svg>
            </IconButton>
          </Tooltip>
        </div>
        <div>
          {state && state.length > 0 &&
          <CardGrid state={state} setState={handleChange} isSmall columns={columns} canEdit={canEdit}>
            {({ card }) => (
              <Card className={(canEdit ? 'cursor-grab' : 'cursor-pointer') + ' w-full h-full'} 
                deleteClick={canEdit && ((click) => handleRemove(click, card))} 
                handleClick={() => {cardClick && cardClick(card);}}
                statusFunction={card?.systemId ? GetSystemStatus(card.systemId, fetchStatus) : GetLocationStatus(card.locationId, fetchStatus)}
              >
                <p className="text-xs uppercase mb-px">{card?.systemId ? 'System' : 'Bygg'}</p>
                <p className="font-semibold text-base">{attachCardInfo(card)?.title}</p>
                <p className="text-sm">{card?.systemId ? customer?.locations?.find(location => location.id === attachCardInfo(card)?.locationId)?.title : attachCardInfo(card)?.address}</p>
               
              </Card>
            )}
          </CardGrid>}
        </div>
      </div>
    </div>
  );
}
