import { useEffect, useState } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowsDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function useListView() {
  const [useListView, setListView] = useState(true);
  function handleResize() {
    if (getWindowDimensions().width < 768){
        
      setListView(false);
    }else{
      setListView(true);
    }
  }
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

  }, []);

  return useListView;
}
