import { useAppContext } from 'components/Context/AppWrapper';
import { useGetSample } from 'components/Hooks/useGetSample';
import { useSystemContext } from '../context';

export function SensorSampler({ position, g, polygon, text }) {
  const { status: fetchStatus } = useAppContext();
  const { getSensor, selected, setSelected, edit } = useSystemContext();
  const sensorId = getSensor(position);
  
  const sample = useGetSample(sensorId);
  const status = fetchStatus?.sensors?.find(x => x.id === sensorId)?.status;
  
  const isSelected = position === selected;
  
  // const res = sample?.status;
  // console.log(sensor?.id, res);
  // if(status) return res;
  
  // const transform = sensorId && t(sample?.v, sensorId);
  const transform = sample?.v;
  
  function getStatusClassNames(status) {
    switch (status) {
    case 1:
      return 'red';
    default:
      return '#e6e6e6';
    }
  }
  
  return (
    <g
      {...g}
      onPointerUp={() => setSelected(position)}
    >
      <polygon
        {...polygon}
        stroke={isSelected ? '#77c9f2' : sample ? getStatusClassNames(status) : '#e6e6e6'}
        fill={isSelected ? '#77c9f2' : sample ? getStatusClassNames(status) : '#e6e6e6'}
      />
      <text
        {...text}
      >
        {edit ? sensorId : transform}
      </text>
    </g>
  );
}