import { deviceService } from 'services';

export function SensorOptionSelect({ id, initialValue, option }) {
  function updateSensor(e) {
    const value = e.target.value;
    deviceService.updateSensor(id, { [option]: value });
  }
  
  return (
    <select onChange={updateSensor} className="appearance-none p-1 ph-2 form-select" defaultValue={initialValue}>
      <option value={true}>True</option>
      <option value={false}>False</option>
    </select>
  );
}