import { useState, useEffect } from 'react';
import { customerService } from 'services/customer.service';
import { SettingsIcon, WeatherArrow, XMark } from 'components/icons';
import { hasAccess, Role } from 'utils';
import { accountService } from 'services';
import { Loading } from 'components/Modules/Loading';
import Select from 'react-select';
import weatherLookup from 'screens/dashboard/MyDashboard/weatherLookup.json';


export function Weather() {
  const customer = customerService.customerValue;
  const user = accountService.userValue;
  const [forecastLocationId, setForecastLocationId] = useState(customer.weatherSettings ? customer.weatherSettings : 0);
  const [forecast, setForecast] = useState(null);
  const [hideWeatherCardSettings, setHideWeatherCardSettings] = useState(true);

  const forecastLocation = customer?.locations[forecastLocationId];
  
  async function getWeather() {
    try {
      const cordResult = await fetch(`https://ws.geonorge.no/adresser/v1/sok?sok=${forecastLocation?.address}&fuzzy=false&utkoordsys=4258&treffPerSide=10&side=0&asciiKompatibel=true`);
      const cordParsed = await cordResult.json();
      const { lat: forecastLat, lon: forecastLon } = cordParsed.adresser[0].representasjonspunkt;
  
      const weatherResult = await fetch(`https://api.met.no/weatherapi/nowcast/2.0/complete.json?lat=${forecastLat}&lon=${forecastLon}`);
      const weatherParsed = await weatherResult.json();
      const { timeseries } = weatherParsed.properties;
      const forecastData = timeseries[0].data;
  
      const forecastType = forecastData.next_1_hours.summary.symbol_code;
      const forecastImage = weatherLookup[forecastType].imageLink;
      const forecastTemp = Math.round(forecastData.instant.details.air_temperature);
      const forecastWindDirection = Math.round(forecastData.instant.details.wind_from_direction);
      const forecastWindSpeed = Math.round(forecastData.instant.details.wind_speed);
      const forecastTempColor = forecastTemp > 0 ? 'text-red-500' : 'text-blue-700';
  
      setForecast({
        temp: forecastTemp,
        tempColor: forecastTempColor,
        image: forecastImage,
        windSpeed: forecastWindSpeed,
        windDirection: forecastWindDirection,
        location: forecastLocation?.title

      });
    } catch (error) {
      console.error(error);
    }
  }

  function weatherGridSettingsChangeHandler(e){
    customerService.update(customer.id, {
      ...customer,
      weatherSettings: e.value,
    });
    setForecastLocationId(e.value);
    getWeather();
    setHideWeatherCardSettings(true);
  }

  function WeatherGridSettingsOptions(data) {
    return data.map((item, index) => ({
      value: index,
      label: `${item.title} - ${item.address}`
    }));
  }

  useEffect(() => {
    getWeather();
    const interval = setInterval(() => {
      getWeather();
    }, 300000);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastLocationId]);

  return <>
    <div id='weatherGrid' className={hideWeatherCardSettings ? '' : 'hidden'}>
      <a id='weatherSettings' className={(hasAccess(user, Role.CustomerAdmin) ? 'absolute top-3 right-3 opacity-40 hover:opacity-100' : 'hidden')} href="#" onClick={() => setHideWeatherCardSettings(false)}><SettingsIcon /></a>
      <p id='disclaimer' className='absolute bottom-2 left-3 text-xs italic text-slate-500'>Værdata er hentet fra yr.no</p>

      {forecast ? (
        <><p id='locationName' className='text-slate-700 text-xl md:text-2xl'>{forecast.location}</p><div id='weather' className='w-full'>
          <img id='weatherImage' className='float-left' src={forecast.image} />
          <div id='weatherText' className='py-5'>
            <p id='temperature' className={'float-left align-middle text-3xl ' + forecast.tempColor}>{forecast.temp}&deg;C</p>
            <div id="wind" className='float-right px-2'>
              <p id='windArrow' className={'text-xl rotate-[' + forecast.windDirection + 'deg]'}><WeatherArrow /></p>
              <p id='windSpeed' className='text-center text-xs text-slate-700'>{forecast.windSpeed} m/s</p>
            </div>
          </div>
        </div>
        </>
      ):(
        <Loading/>
      )}
    </div>
    <div id='weatherGridSettings' className={hideWeatherCardSettings ? 'hidden' : ''}>
      <a id='exitWeatherSettings' className='absolute top-3 right-3 opacity-40 hover:opacity-100' href="#" onClick={() => setHideWeatherCardSettings(true)}><XMark /></a>
      <div className="weatherSettings pt-4">
        <label htmlFor='weatherGridChooser'>Velg lokasjon</label>
        <Select name='weatherGridChooser' options={WeatherGridSettingsOptions(customer?.locations)} defaultValue={WeatherGridSettingsOptions(customer?.locations)[forecastLocationId]} onChange={(e) => weatherGridSettingsChangeHandler(e)}></Select>
      </div>
    </div>
  </>;
}
