import { useState } from 'react';
import { Formik, Form } from 'formik';
import { customerService } from 'services';
import toast from 'react-hot-toast';
import { FormGroup, FormSubmit, FormSearchAutocomplete } from 'components/Elements/forms';
import { useNavigate } from 'react-router-dom';

export function AddCustomer(){
  const [resultValue, setResultValue] = useState(null);
  const navigate = useNavigate();

  async function onSubmit(){
    const toastId = toast.loading('Oppretter kunde...');
    try {
      const customer = await customerService
        .create({orgNumber: resultValue});
      toast.success(`${customer.orgName} ble opprettet`, { id: toastId });
      navigate('/dashbord/kunder');
    } catch (
      err
    ) {
      toast.error('Kunde ble ikke opprettet', { id: toastId });
    }
  }
  
  return (
    <Formik
      initialValues={{
        orgname: ''}}
      onSubmit={onSubmit}
    >
      <Form>
        <div className='contain'>
          <FormGroup>
            <div className={'text-center px-3'}>
              <h1 className='p-2'>Opprett kunde</h1>
            </div>
            <FormSearchAutocomplete 
              name='orgname' 
              title='Organisasjonsnavn' 
              queryUrl='https://data.brreg.no/enhetsregisteret/api/enheter?navn=' 
              dataFilter='_embedded.enheter' 
              dataDropdownDisplay='navn' 
              clickFunction={setResultValue} 
              dataToClickFunction='organisasjonsnummer'/>
            <FormSubmit title='Opprett kunde'/>
          </FormGroup>
        </div>
      </Form>
    </Formik>
  );
} 
