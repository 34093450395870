import config from 'config';
import { fetchWrapper } from 'utils';

const baseUrl = `${config.deviceApiUrl}/sensors`;

export const sensorService = {
  getDeviceSensors,
  getStatus,
  getAll,
  update,
  getAllSensorOutputs,
  reset,
  getById
};

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function reset(id) {
  return fetchWrapper.delete(`${baseUrl}/reset/${id}`);
}

function getAllSensorOutputs() {
  return fetchWrapper.get(`${baseUrl}/outputs`);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params);
}

function getAll() {
  return fetchWrapper.get(`${baseUrl}/`);
}

function getDeviceSensors(deviceId) {
  return fetchWrapper.get(`${baseUrl}/device/${deviceId}`);
}

function getStatus(sensorId) {
  return fetchWrapper.get(`${baseUrl}/${sensorId}`);
}