import { deviceService } from 'services';

export function SensorController({ id, initialValue, controller, interfaces }) {
  const controllers = interfaces?.find(elem => elem.id === controller.interfaceId)?.controllers;

  function updateSensor(e) {
    const value = e.target.value;
    deviceService.updateSensor(id, { controllerId: value });
  }
  
  return (
    <select className="appearance-none p-1 ph-2 form-select" defaultValue={initialValue} onChange={e => updateSensor(e)}>
      {controllers?.map(controller => (
        <option key={controller.id} value={controller.id}>{controller.id}</option>
      ))

      }
    </select>
  );
}