import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { accountService } from 'services/account.service';
import { DashboardWrapper } from '../components/DashboardWrapper';

export function Message() {
  const [textValue, setTextValue] = useState('');
  const [users, setUsers] = useState(null);
  const [selected, setSelected] = useState('');

  useEffect(() => {
    accountService
      .getAll()
      .then((x) =>
        setUsers(
          x.map((y) => {
            return {
              label: `${y.firstName} ${y.lastName}, ${y.customer ? y.customer.title : 'Admin'}, ${y.phone}`,
              value: y.phone,
            };
          })
        )
      )
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="p-5 text-2xl space-y-3 pb-20 col-span-full">
      <div className="p-5 text-center">
        <p>Send melding</p>
      </div>
      <div className="flex place-content-center">
        <div className="w-1/2 flex flex-col space-y-2">
          <div>
            <label className="text-base font-semibold uppercase">Mottakere</label>
            <Select
              //defaultValue={[]}
              value={selected}
              placeholder="Velg mottakere"
              isMulti
              name="Send melding"
              options={users && users}
              className="text-base"
              onChange={(e) => setSelected(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-base font-semibold uppercase">Melding</label>
            <textarea
              rows="4"
              className="rounded-md border border-gray-300 text-base p-2"
              value={textValue}
              onChange={(e) => setTextValue(e.target.value)}
            />
          </div>
          <button
            className="border-2 border-green-500 px-3 py-1 rounded-full"
            onClick={() => {
              accountService
                .notify({
                  recipients: selected.map((x) => {
                    return x.value;
                  }),
                  msg: textValue,
                })
                .then(() => toast.success('Melding sendt!'))
                .catch(() => toast.error('Feil ved sending av melding'));
            }}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
}
