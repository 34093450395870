import { NavLink } from 'react-router-dom';
import { classNames } from 'utils/classNames';
import { customerService } from 'services';
import { hasAccess } from 'utils';
import { accountService } from 'services';
import MyDashboards from 'screens/dashboard/components/MyDashboards';
import { Role } from 'utils';
import { adminNavigation, userNavigation } from '../navigation';
import { WidgetGrid } from './Widget';
import { Fragment, useEffect, useState } from 'react';
import { useDashboardContext } from '../context';

export function DashboardWrapper({ children, menuItems, hidden, title, currentPath='', defaultPathname='Dashbord', defaultPath='dashbord', hidePath }) {
  const user = accountService.userValue;
  const customer = customerService.customerValue;
  const { path, setPath, pathname, setPathname } = useDashboardContext();

  const [minimized, setMinimized] = useState(false);

  menuItems = menuItems ? menuItems : (hasAccess(user, Role.Admin) ? adminNavigation : userNavigation);

  function toUpperCase(string) {
    return (string.charAt(0).toUpperCase() + string.slice(1));
  }

  useEffect(() => {
    if(!hidePath) setPathname([defaultPathname, title]);
    if(!hidePath) setPath([defaultPath, currentPath]);
  }, [hidePath, defaultPathname, title, setPathname, defaultPath, currentPath, setPath]);

  var pathArray = [];
  if (path && pathname) {
    if (pathname.length > 0) {
      for (let i = 0; i < pathname.length-1; i++) {
        let exactPath = `${path[0]}`;
        for (let j = 1; j < i+1; j++) {
          exactPath += `/${path[j]}`;
        }
        pathArray.push(<Fragment key={i}>
          <NavLink to={`/${exactPath}`}><p className="hover:underline text-sm text-gray-500 font-semibold">{pathname[i]}</p></NavLink>
          <p className="mx-2 text-sm text-gray-500 font-semibold">-</p>
        </Fragment>);
      }
      pathArray.push(<p key={pathname} className="text-sm text-sky-600 font-semibold">{pathname[pathname.length-1]}</p>);
    }
  }

  return (
    <section className="flex flex-col md:flex-row md:h-full relative">
      {!hidden && (
        <div className="md:h-auto w-full md:w-fit md:max-w-66 shadow-lg flex-initial z-99 flex-row flex bg-white sticky top-0 overflow-x-auto thin-scrollbar">
          <ul className="flex text-left flex-row md:flex-col mx-auto w-full">
            <div className={`${minimized && 'mb-4 mt-3'} hidden md:flex justify-center cursor-pointer w-full relative`} onClick={() => setMinimized(!minimized)}>
              {minimized ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="h-7 w-7 absolute right-0 mt-2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                </svg>
              
              )
              }
            </div>
            <div className={`py-6 px-6 hidden ${!minimized && 'md:block'}`}>
              <p className="break-words text-black text-xl md:text-3xl">{customer ? customer.orgName : 'Admin'}</p>
            </div>
            {menuItems.map(item => {
              if ( item.role && !hasAccess(user, item.role)) return null;
              if(item.name) return (
                <NavLink
                  key={item.href}
                  to={`${item.href}`}
                  className={({isActive}) => classNames(
                    isActive
                      ? 'bg-blue-300 bg-opacity-20 text-sky-600 font-semibold'
                      : 'text-gray-600 hover:bg-blue-300 hover:bg-opacity-20',
                    'px-6 py-2 text-16 font-medium flex'
                  )}
                  aria-current={({isActive}) => isActive ? 'page' : undefined}
                  end
                >
                  <div className={`w-6 py-1 ${!minimized && 'md:w-4.5'} my-auto ${!minimized && 'md:mr-3'}`}>
                    {item.icon}
                  </div>
                  <div className='my-auto'>
                    <p className={`hidden ${!minimized && 'md:block'}`}>{item.name}</p>
                  </div>
                </NavLink>
              );
            })}
            {/* {customer && (
              <MyDashboards />
            )} */}
          </ul>
        </div>
      )}
      <div className="w-full bg-blue-100 bg-opacity-20 z-0 overflow-y-hidden">
        {(title || pathname) && (
          <div className="w-full bg-blue-300 bg-opacity-20 px-7 py-3 flex h-13 flex-wrap">
            <p className="my-auto text-lg text-sky-600 font-semibold">{title}</p>
            { !hidePath &&
              <div className="my-auto ml-auto hidden sm:flex">
                {pathArray}
              </div>
            }
          </div>
        )}
        <WidgetGrid>
          {children}
        </WidgetGrid>
      </div>
    </section>
  );
}
