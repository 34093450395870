import { useSystemContext } from '../../context';
import SensorInput from './Input';
import SensorInterval from './Interval';
import SensorOffset from './Offset';
import SensorInvert from './Invert';
import SensorTag from './Tag';

export default function Sensor() {
  const { selected, sensor } = useSystemContext();

  return (
    <>
      {selected && (
        <div className="w-11/12 m-2 ml-0 border-l pl-4">
          <p className="font-bold text-2xl">Sensor</p>
          <div className="flex space-x-7">
            <div className="w-1/3 w-">
              <SensorInput />
            </div>
            {sensor && (
              <div className="flex w-2/3 space-x-5 pr-3">
                <SensorInterval />
                <SensorOffset />
                <SensorInvert />
                <SensorTag />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}