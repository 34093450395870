import { useCallback, useEffect, useRef, useState } from 'react';
import { deviceService, sampleService } from 'services';
import { Chart } from './Chart';
import { useSystemContext } from '../context';
import { sensorOutputs } from 'utils/samples';

export function ChartContainer() {
  const { sensor, selected } = useSystemContext();
  const [data, setData] = useState(null);
  const [zoom, setZoom] = useState('');
  const [view, setView] = useState('avg');
  const [controllers, setControllers] = useState(null);
  const minView = useRef(null);
  const maxView = useRef(null);
  const startTime = useRef(null);
  const endTime = useRef(null);

  const unit = sensorOutputs.filter(elem => elem.id === sensor.output)[0]?.unit;

  useEffect(() => {
    const controllerArray = [];
    deviceService.getInterfaces().then(res => {
      res.forEach(i => i.controllers.forEach(c => controllerArray.push(c)));
      setControllers(controllerArray);
    });
  }, []);

  const getData = useCallback(async (min = null, max = null, format = view) => {
    startTime.current = new Date();

    const range = max - min;
    const bucket = range ? Math.ceil(Math.round(range) / 100000) : 20000;
    const samples = await sampleService.getById(sensor.id, bucket, min, max, format);
    if (samples) {
      const res = [];
      if (samples.length <= 0) return;
      for (const point of samples) {
        res.push({
          x: new Date(point.t),
          y: parseFloat(point?.v),
        });
      }
      minView.current = res[0].x.getTime();
      maxView.current = res[res.length - 1].x.getTime();
      setData([
        {
          name: sensor.tag ? sensor.tag : 'Sensor',
          data: res,
        },
      ]);
      endTime.current = new Date();
      console.log('Rendered ' + res.length + ' datapoints in ' + (endTime.current - startTime.current) + 'ms.');
    }
  }, [sensor.id, sensor.tag, view]);

  useEffect(() => {
    if (selected) {
      console.log('Trigger');

      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensor, selected]);

  function rangeSelector(viewportMinimum, viewportMaximum) {
    getData(viewportMinimum, viewportMaximum);
  }

  const zoomLevelObjects = [
    { title: 'Alle', value: '' },
    { title: '1 år', value: '31536000000' },
    { title: '30 dager', value: '2592000000' },
    { title: '24 t', value: '86400000' },
  ];

  const aggregateObjects = [
    { value: 'avg', active: false, title: 'Gjennomsnitt' },
    { value: 'min', active: false, title: 'Min' },
    { value: 'max', active: false, title: 'Max' },
  ];

  function changeZoom(val) {
    setZoom(val);
    if (val) {
      rangeSelector(+new Date() - val, +new Date());
    } else {
      rangeSelector();
    }
  }

  function changeView(val) {
    setView(val);
    getData(minView.current, maxView.current, val);
  }

  return (
    <>
      <div className="absolute z-999 md:left-20 md:-mt-1 text-sm flex flex-col space-y-2 sm:space-y-0 sm:space-x-1 sm:flex-row md:space-x-4 lg:space-x-5">
        <div>
          {zoomLevelObjects.map((elem) => (
            <button
              key={elem.title}
              onClick={() => changeZoom(elem.value)}
              className={`px-1.5 border-b border-t border-l py-1 ${elem.value === '' && 'rounded-l-lg'} ${
                elem.value === '86400000' && 'border-r rounded-r-lg'
              } ${
                elem.value === zoom
                  ? 'bg-sky-600 border-sky-600 hover:bg-sky-700 hover:border-sky-700'
                  : 'hover:bg-sky-600 hover:border-sky-600 hover:border-opacity-70'
              }`}
            >
              {elem.title}
            </button>
          ))}
        </div>
        <div>
          {aggregateObjects.map((elem) => (
            <button
              key={elem.value}
              onClick={() => changeView(elem.value)}
              className={`px-1.5 border-b border-t border-l py-1 ${elem.value === 'avg' && 'rounded-l-lg'} ${
                elem.value === 'max' && 'border-r rounded-r-lg'
              } ${
                elem.value === view
                  ? 'bg-sky-600 border-sky-600 hover:bg-sky-700 hover:border-sky-700'
                  : 'hover:bg-sky-600 hover:border-sky-600 hover:border-opacity-70'
              }`}
            >
              {elem.title}
            </button>
          ))}
        </div>
      </div>
      <div className="mt-10 md:mt-0">
        <Chart data={data} zoom={setZoom} update={getData} unit={controllers?.find(e => e.id === sensor?.controllerId).unit || ''}></Chart>
      </div>
    </>
  );
}
