import { useMemo, useState, useEffect } from 'react';
import Table from 'components/Modules/table/Table';
import { deviceService } from 'services';
import { useParams } from 'react-router-dom';
import { SensorOptionInput } from './SensorOptionInput';
import { SensorOptionSelect } from './SensorOptionSelect';
import { SensorValue } from './SensorValue';
import { SensorReset } from './SensorReset';
import { SensorController } from './SensorController';

export function DeviceSensors() {
  const { id } = useParams();
  const [device, setDevice] = useState(null);
  const [interfaces, setInterfaces] = useState(null);

  useEffect(() => {
    deviceService.getById(id).then((device) => {
      setDevice(device);
    });
    deviceService.getInterfaces().then((res) => {
      setInterfaces(res);
    });
  }, [id]);

  const sensorColumns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Tag',
        accessor: 'tag',
        Cell: ({ value, row }) => SensorOptionInput({ id: row.original.id, initialValue: value, option: 'tag', isText: true })
      },
      {
        Header: 'localId',
        accessor: 'localId',
      },
      // {
      //   Header: 'Status',
      //   accessor: 'status',
      //   Cell: StatusPill,
      // },
      {
        Header: 'Intervall',
        accessor: 'interval',
        Cell: ({ value, row }) => SensorOptionInput({ id: row.original.id, initialValue: value, option: 'interval' })
      },
      {
        Header: 'Offset',
        accessor: 'offset',
        Cell: ({ value, row }) => SensorOptionInput({ id: row.original.id, initialValue: value, option: 'offset' })
      },
      {
        Header: 'Invert',
        accessor: 'invert',
        Cell: ({ value, row }) => SensorOptionSelect({ id: row.original.id, initialValue: value, option: 'invert' })
      },
      {
        Header: 'Interface',
        id: 'interfaceId',
        accessor: 'controller.interfaceId',
      },
      {
        Header: 'Kontroller',
        accessor: 'controller.id',
        Cell: ({ value, row }) => SensorController({ id: row.original.id, initialValue: value, controller: row.original.controller, interfaces: interfaces })
      },
      {
        Header: 'Verdi',
        id: 'value',
        accessor: 'id',
        Cell: SensorValue
      },
      {
        Header: 'Slett data',
        id: 'reset',
        accessor: 'id',
        Cell: SensorReset
      },
    ],
    [interfaces]
  );

  function handleClick(object) {
    // history.push(`devices/${object.id}/edit`);
  }

  return (
    <>
      {device?.sensors && <Table columns={sensorColumns} data={device?.sensors} handleClick={handleClick} />}
    </>
  );
}
