import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { systemService } from 'services/system.service';
import { CanvasView } from 'components/Modules/transformable/CanvasView';
import { TrashIcon, PencilAltIcon } from '@heroicons/react/outline';
import { Loading } from '../Loading';

export function CanvasList({ pages, setPages, setPage }) {
  const navigate = useNavigate();

  function removePage(id) {
    systemService
      .removePage(id)
      .then(() => {
        setPages(pages.filter((page) => page.id !== id));
        toast.success('Page removed!');
      })
      .catch(() => toast.error('Could not remove page'));
  }

  function pageClick(page, alt) {
    setPage(pages.filter((x) => x.id === page.id)[0]);
    navigate('build');
  }

  return (
    <>
      {pages ? (
        <div className="grid grid-cols-8 gap-4 m-2">
          {pages.map((page) => (
            <div key={page.id} className="">
              <div
                className="h-max p-1 border-2 border-black border-opacity-10 rounded-md hover:opacity-50"
                onClick={() => pageClick(page, 'view')}
              >
                <div className="">
                  <CanvasView items={page.items} />
                </div>
              </div>
              <div className="flex space-x-1">
                <div className="w-2/3 ml-2 font-base">{page.title}</div>
                <div className="w-1/3 flex place-content-center">
                  <PencilAltIcon
                    className="h-6 hover:text-blue-600 cursor-pointer"
                    onClick={() => pageClick(page, 'build')}
                  />
                  <TrashIcon className="h-6 hover:text-red-600 cursor-pointer" onClick={() => removePage(page.id)} />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}
