import { Route, Routes, useParams } from 'react-router-dom';
import { EditCustomer } from 'components/Modules/addedit/EditCustomer';
import { CustomerTable } from 'components/Modules/table/CustomerTable';
import { UserTable } from 'components/Modules/table/UserTable';
import { LocationTable } from 'components/Modules/table/LocationTable';
import { AddEditLocation } from 'components/Modules/addedit/AddEditLocation';
import { AddCustomer } from 'components/Modules/addedit/AddCustomer';
import { SystemTable } from 'components/Modules/table/SystemTable';
import { AddEditSystem } from 'components/Modules/addedit/AddEditSystem';
import { Widget } from '../components/Widget';
import { useDashboardContext } from '../context';
import { useEffect } from 'react';
import { customerService } from 'services';

export function Customers({ displayedPath }) {
  return (
    <Routes>
      <Route index path={'/'} element={<Widget><CustomerTable /></Widget>} />
      <Route path={'add'} element={<Widget><AddCustomer /></Widget>} />
      <Route path={':customerId/edit'} element={<Widget><EditCustomer /></Widget>} />
      <Route path={':customerId/*'} element={<CurrentCustomer displayedPath={displayedPath} />} />
    </Routes>
  );
}

export function CurrentCustomer({ displayedPath }) {
  const { setPathname } = useDashboardContext();
  const { customerId } = useParams();

  useEffect(() => {
    customerService.getById(customerId).then(x => {
      setPathname([displayedPath, x.title].flat());
    });
    return () => {
      setPathname(displayedPath);
    };
  }, [displayedPath, setPathname, customerId]);

  return (
    <Routes>
      <Route path={'/'} element={
        <>
          <Widget>
            <LocationTable />
          </Widget>
          <Widget>
            <UserTable filter />
          </Widget>
        </>
      } />
      <Route path={'add'} element={<Widget><AddEditLocation /></Widget>} />
      <Route path={':locationId/edit'} element={<Widget><AddEditLocation /></Widget>} />
      <Route path={':locationId/*'} element={
        <Widget>
          <Routes>
            <Route path={'/'} element={<SystemTable />} />
            <Route path={'add'} element={<AddEditSystem />} />
            <Route path={':systemId/edit'} element={<AddEditSystem />} />
          </Routes>
        </Widget>
      } />
    </Routes>
  );
}