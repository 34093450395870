import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

import { accountService } from 'services';
import toast from 'react-hot-toast';
import { SubmitButton, LinkButton } from 'components/Elements/Button';

function ForgotPassword() {
  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Ugyldig e-postadresse').required('Skriv inn e-post'),
  });

  function onSubmit({ email }, { setSubmitting }) {
    accountService
      .forgotPassword(email)
      .then(() => toast.success('Følg instruksjonene sendt til din e-post'))
      .catch((error) => toast.error(error))
      .finally(() => setSubmitting(false));
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <p className="text-5xl mb-4 leading-none">Glemt passord</p>
          <div className="card-body">
            <p className="mb-3">Skriv inn e-post for å få tilsendt gjenopprettingslenke</p>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2 ml-px">E-post</label>
              <Field
                name="email"
                type="text"
                className={
                  'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' +
                  (errors.email && touched.email ? ' border-red-600' : '')
                }
              />
            </div>
            <div className="mt-8 block xs:flex">
              <div className="block xs:flex">
                <SubmitButton className="w-26">
                  Send
                </SubmitButton>
                <LinkButton to="/account/login" className="w-26 xs:ml-3 mt-2 xs:mt-0">
                  Avbryt
                </LinkButton>
              </div>
            </div>
            {/* <div className="form-row mt-4">
              <div className="form-group col">
                <button type="submit" disabled={isSubmitting} className="reset">
                  {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                  <p className="btn btn-circle bg-dark text-white">Send</p>
                </button>
                <Link to="login" className="reset" style={{ display: 'inline-block' }}>
                  <p className="btn">Avbryt</p>
                </Link>
              </div>
            </div> */}
          </div>
        </Form>
      )}
    </Formik>
  );
}

export { ForgotPassword };
