import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import Avatar from 'react-avatar';
import { accountService } from 'services';

const defaultNav = [
  // { name: 'Demo', href: '/demo', current: false },
  // { name: 'Om oss', href: '/about', current: false },
];

const userNavigation = [
  { name: 'Dashbord', href: '/dashbord' },
  { name: 'Profil', href: '/profil' },
  { name: 'Logg ut', href: '/account/logout'},
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function Header() {
  const user = accountService.userValue;
  const loc = useLocation();
  const navigation = defaultNav.map((item) => {
    return {
      ...item,
      current: item.href === loc.pathname,
    };
  });

  return (
    <div>
      <Disclosure as="nav" className="bg-ed-primary">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0" style={{ marginTop: '-3px' }}>
                    <NavLink to="/">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ display: 'inline-block', marginTop: '-10px' }}
                        className="h-8 w-9"
                        fill="none"
                        viewBox="0 0 20 20"
                        stroke="white"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                      </svg>
                      <p
                        style={{
                          display: 'inline-block',
                          fontSize: '22px',
                          color: 'white',
                          paddingLeft: '10px',
                        }}
                      >
                        enkeldrift
                      </p>
                    </NavLink>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current ? 'underline text-white' : 'text-gray-300 hover:underline hover:text-white',
                            'px-3 py-2 rounded-md text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    {user && (
                      <div className="flex space-x-3">
                        {/* <NavLink
                          to={'/dashbord'}
                          className="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white border border-white"
                        >
                          Dashbord
                        </NavLink> */}
                        {/* <button
                          type="button"
                          className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-ed-primary focus:ring-white"
                        >
                          <span className="sr-only">View notifications</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            aria-hidden="true"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                            />
                          </svg>
                        </button> */}
                      </div>
                    )}

                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 relative z-999">
                      {user ? (
                        <>
                          <div className='flex'>
                            <div className='my-auto mr-3 text-right'>
                              <p className='text-sm text-white font-medium'>{user.firstName} {user.lastName}</p>
                              <p className='text-xs text-gray-300'>{user.email}</p>
                            </div>
                            <Menu.Button className="max-w-xs bg-ed-primary rounded-full flex items-center text-sm focus:outline-none hover:ring-2 hover:ring-offset-1 hover:ring-offset-ed-primary hover:ring-sky-600">
                              <span className="sr-only">Open user menu</span>
                              {/* <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" /> */}
                              <Avatar
                                name={user.firstName + ' ' + user.lastName}
                                round={true}
                                size={40}
                                textSizeRatio={2.5}
                              />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {userNavigation.map((item) => (
                                <Menu.Item key={item?.name}>
                                  {({ active }) => (
                                    <NavLink
                                      to={item?.href}
                                      onClick={item?.onClick}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700'
                                      )}
                                    >
                                      {item.name}
                                    </NavLink>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </>
                      ) : (
                        <NavLink
                          to="/account/login"
                          className="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white border border-gray-300 hover:border-white"
                        >
                          Logg inn
                        </NavLink>
                      )}
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-ed-primary inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-ed-primary focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                      </svg>
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigation.map((item) => (
                  <a
                    key={item?.name}
                    href={item?.href}
                    className={classNames(
                      item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block px-3 py-2 rounded-md text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              {user ? (
                <div className="pt-4 pb-3 border-t border-gray-700">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <Avatar name={user.firstName + ' ' + user.lastName} round={true} size={40} textSizeRatio={2.5} />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">{user.firstName} {user.lastName}</div>
                      <div className="text-sm font-medium leading-none text-gray-400">{user.email}</div>
                    </div>
                    {/* <button
                      type="button"
                      className="ml-auto bg-ed-primary flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-ed-primary focus:ring-white"
                    >
                      <span className="sr-only">View notifications</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                        />
                      </svg>
                    </button> */}
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    {userNavigation.map((item) => (
                      <a
                        key={item.name}
                        href={item?.href}
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="pt-2 pb-3 border-t border-gray-700">
                  <div className="mt-3 px-5 mb-2">
                    <NavLink
                      to="/account/login"
                      className="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white border border-gray-300 hover:border-white"
                    >
                      Logg inn
                    </NavLink>
                  </div>
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
