import { Field, Form, ErrorMessage } from 'formik';
import { useState } from 'react';
import { fetchFromObject } from 'utils/fetchFromObject';

export function FormGroup({ children }) {
  return <div className="row justify-content-center">{children}</div>;
}
/**
 *
 * @param {component} children form content
 * @returns
 */

export function FormContainer({ children, clean = false }) {
  if (!clean) {
    return (
      <Form className="bg-white rounded px-8 pt-6 pb-8 border-t-gray-100">
        <div className="flex-wrap mb-6 flex">{children}</div>
      </Form>
    );
  } else {
    return <Form>{children}</Form>;
  }
}

/**@todo Check this */
export function FormInput({ title, type, name, errors, touched, value, className, onChange, placeholder, nPerRow = 1, rows = 3 }) {
  return (
    <div className={`w-full mb-2 md:w-1/${nPerRow} ${className}`}>
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1">{title}</label>
      <Field
        name={name}
        className={
          'appearance-none block w-full text-gray-700 border border-gray-300 hover:border-gray-500 rounded p-2 mb-3 leading-tight focus:outline-1 outline-sky-600 focus:bg-white' +
          (errors && touched ? ' border-red-500' : '') +
          (value ? ' bg-white' : ' bg-gray-100')
        }
        type={type}
        rows={rows}
        component={type === 'textarea' ? type : ''}
        placeholder={placeholder && placeholder}
      />
      <ErrorMessage name={name} component="div" className="-mt-2 text-red-500 text-xs mb-2" />
    </div>
  );
}

export function FormSelect({ title, type, name, errors, touched, value, cName, children, nPerRow = 1 }) {
  return (
    <div className={`w-full md:w-1/${nPerRow} mb-6 md:mb-0 ${cName}`}>
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">{title}</label>
      <Field
        name={name}
        as="select"
        className={
          'appearance-none block w-full text-gray-700 border border-gray-300 hover:border-gray-500 rounded p-2 mb-3 leading-tight focus:outline-1 outline-sky-600 focus:bg-white' +
          (errors && touched ? ' border-red-500' : '') +
          (value ? ' bg-white' : ' bg-gray-100')
        }
        type={type}
      >
        {children}
      </Field>
      <ErrorMessage name={name} component="div" className="invalid-feedback text-red-500" />
    </div>
  );
}

export function FormSearchAutocomplete({title, name, value, queryUrl, dataFilter, dropdownLimit, clickFunction, dataDropdownDisplay, dataToClickFunction, nPerRow = 1}){
  // Default values
  value = value ?? '';

  dropdownLimit = dropdownLimit ?? 10;

  dataToClickFunction = dataToClickFunction ?? dataDropdownDisplay;

  const [childrenArray, setChildrenarray] = useState(null);
  const [fieldValue, setFieldvalue] = useState(value);

  function arrayFetch(array, filter){
    if (Array.isArray(filter)){
      var output = '';
      for (let i = 0; i < filter.length; i++){
        output += fetchFromObject(array, filter[i]) + (i !== filter.length-1 ? ' ' : '');
      }
      return output;
    } else {
      return fetchFromObject(array, filter);
    }
  }

  function FieldDropdown(array){
    if (array.children && array.children.length > 0){
      return(
        <div className="absolute z-10 -mt-2 w-25/25 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
          <div className="py-1" role="none">
            {array.children.slice(0, dropdownLimit).map((list, index) => (
              <a 
                href="#" 
                key={index} 
                className="text-gray-700 block px-4 py-2 text-sm" 
                role="menuitem" 
                tabIndex="-1" 
                id={'menu-item-' + index} 
                onClick={() => {
                  setFieldvalue(arrayFetch(list, dataDropdownDisplay)); 
                  setChildrenarray(null); 
                  clickFunction(arrayFetch(list, dataToClickFunction));}}>
                {arrayFetch(list, dataDropdownDisplay)}
              </a>
            ))}
          </div>
        </div>
      );
    }
  }

  const updateValue = event => {
    setFieldvalue(event.target.value);
    if (event.target.value){
      fetch(queryUrl + encodeURIComponent(event.target.value))
        .then(response => response.json())
        .then(data => setChildrenarray(fetchFromObject(data, dataFilter)));
    } else {setChildrenarray(null);}
  };

  return (
    <div className={`w-full md:w-1/${nPerRow} mb-6 md:mb-0`}>
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">{title}</label>
      <Field 
        className='appearance-none block w-full text-gray-700 border border-gray-300 hover:border-gray-500 rounded p-2 mb-3 leading-tight focus:outline-1 outline-sky-600 focus:bg-white' 
        type='text' 
        name={name} 
        value={fieldValue} 
        onChange={updateValue} />
      <FieldDropdown children={childrenArray}/>
      <ErrorMessage name={name} component="div" className="-mt-2 text-red-500 text-xs mb-2" />
    </div>
  );
}

export function FormCheckbox({title, name, value, onClick, nPerRow = 1}) {
  return (
    <div className={`w-full mb-2 md:w-1/${nPerRow}`}>
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1">{title}</label>
      <Field name={name} type='checkbox' value={value} onClick={onClick} />
      <ErrorMessage name={name} component="div" className="-mt-2 text-red-500 text-xs mb-2" />
    </div>
  );
}

export function FormSubmit({ children, className, title }) {
  return (
    <button
      type="submit"
      className={
        children ? className : 'text-white hover:bg-gray-500 bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'
      }>
      {children ? children : title ? title : 'Send'}
    </button>
  );
}
