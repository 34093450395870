export function AddEditHeader({ title, isAddMode }) {
  return (
    <div className={'text-center px-3'}>
      <h1 className="p-2">
        {isAddMode ? 'Legg til ' : 'Rediger '}
        {title}
      </h1>
    </div>
  );
}
