import { useEffect, useState } from 'react';
import { systemService } from 'services/system.service';
import { Modal } from 'components/Modules/Modal';
import toast from 'react-hot-toast';
import { Transformable } from 'components/Modules/transformable';
import { useSystemContext } from '../context';

export default function SystemType() {
  const { system, setRemoteSystem } = useSystemContext();
  const [canvasList, setCanvasList] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    systemService
      .getCanvases()
      .then((canvases) => {
        setCanvasList(canvases);
      })
      .catch(() => toast.error('Failed to fetch pages!'));
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="Systemtype" size="xl">
        <div className="flex min-h-screen/2">
          <div className="grid grid-cols-4 gap-4 m-2">
            {canvasList &&
              canvasList.map((canvas) => (
                <div key={canvas.id} className="w-48">
                  <div
                    className={
                      'h-max p-1 border-2 rounded-md hover:opacity-50 ' +
                      (system.canvasId === canvas.id ? 'border-gray-400' : 'border-black border-opacity-10')
                    }
                    onClick={() =>
                      setRemoteSystem({
                        ...system,
                        ...{ canvasId: canvas.id },
                        ...{ canvas: { items: canvas.items } },
                      })
                    }
                  >
                    <div className="">
                      <Transformable.View items={canvas.items} />
                    </div>
                  </div>
                  <div className="flex text-center">
                    <div className="w-full font-base">{canvas.title}</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Modal>
      <div className="w-1/12 m-2 pb-2">
        <p className="font-bold text-2xl">Edit</p>
        <div className="flex-col">
          <p className="font-semibold">Systemtype</p>
          <div>
            {system.pageId ? (
              <div className="flex space-x-2">
                <p>{canvasList && canvasList.filter((canvas) => canvas.id === system.canvasId)[0].title}</p>
                <button
                  className="border-2 border-blue-500 hover:bg-blue-500 py-0.5 px-3 rounded-full"
                  onClick={() => setIsOpen(true)}
                >
                      Endre
                </button>
              </div>
            ) : (
              <button
                className="border-2 border-blue-500 hover:bg-blue-500 py-0.5 px-3 rounded-full"
                onClick={() => setIsOpen(true)}
              >
                    Velg
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}