import { useEffect, useState } from 'react';
import { sensorService } from 'services';
import { Selector } from 'components/Modules/Selector';
import { systemService } from 'services/system.service';
import toast from 'react-hot-toast';
import { useSystemContext } from '../../context';

export default function SensorInput() {
  const { selected, system, setLocalSystem, sensor, resetSensor } = useSystemContext();
  const [sensors, setSensors] = useState(null);

  useEffect(() => {
    sensorService
      .getAll()
      .then((data) => {
        setSensors(
          data.map((elem) => {
            if (elem) {
              return {
                value: { id: elem.id, unit: elem.type === 'abp' ? 'Pa' : '°C', ...elem },
                label: `${elem.id}: ${elem.device?.title}, ${elem.controllerId} (${elem.localId}) - ${elem.tag}`,
                data: elem,
              };
            }
          })
        );
      })
      .catch((err) => {
        // console.log(err);
        toast.error('Failed to fetch sensors!');
      });
  }, []);

  function saveInput(e) {
    const panel = {
      position: selected,
      unit: e.value.unit,
      sensorId: e.value.id,
      systemId: system.id,
    };

    if (sensor) {
      const res = { ...system };
      const panels = res.panels.map(x => {
        if (x.position === selected) {
          return panel;
        }
        return x;
      });
      res.panels = panels;
      // res.panels.filter((x) => x.position === selected)[0] = { ...panel, sensor: e.data };
      setLocalSystem(res);
      systemService.updateSensorPosition(panel);
    } else {
      const res = { ...system };
      res.panels.push({ ...panel, sensor: e.data });
      setLocalSystem(res);
      systemService.addSensorPosition(panel);
    }
  }

  function removePositionSensor() {
    resetSensor();
    systemService.removeSensorPosition(system.id, selected);
    const res = { ...system };
    res.panels = res.panels.filter((x) => x.position !== selected);
    setLocalSystem(res);
  }


  return (
    <div className="">
      <div className="">
        <p className="font-semibold">Input</p>
      </div>
      <div className="flex-grow flex">
        <Selector
          className="w-full"
          data={sensors}
          placeholder={
            sensor ? sensors?.filter((x) => x.value.id === sensor.id)[0].label : 'Select sensor'
          }
          save={saveInput}
        ></Selector>
        {sensor && (
          <div className="h-full w-10 text-gray-600 hover:text-red-600 cursor-pointer" onClick={() => removePositionSensor()}>
            <svg xmlns="http://www.w3.org/2000/svg" className="" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}