import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { deviceService, sensorService, systemService } from 'services';


const SystemContext = createContext({
  system: null,
  setLocalSystem: () => {},
  setRemoteSystem: () => {},
  edit: false,
  setEdit: () => {},
  selected: null,
  setSelected: () => {},
  sensor: null,
  getSensor: () => {}
});

export function SystemWrapper({ children }) {
  const [system, setSystem] = useState(null);
  const [selected, setSelected] = useState(null);
  const [edit, setEdit] = useState(false);
  const [sensor, setSensor] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    async function fetchSensor() {
      if(selected) {
        const sensorId = system.panels.filter((x) => x.position === selected)[0]?.sensorId;
        if (sensorId) {
          const sensor = await sensorService.getById(sensorId);
          setSensor(sensor);
        } else {
          setSensor(null);
        }
      }
    }
    fetchSensor();
  }, [selected, system]);

  useEffect(() => {
    systemService
      .getById(id)
      .then((res) => {
        setSystem(res);
      })
      .catch(() => toast.error('Failed to fetch system'));
  }, [id, setSystem]);

  const setRemoteSystem = useCallback(async (e) => {
    const res = await systemService.update(system.id, e);
    if(res) setSystem(e);
  }, [system]);

  const setEditWithEffects = useCallback(() => {
    setEdit(!edit);
    if (selected) {
      setSelected(null);
    }
  }, [edit, selected]);

  const getSensor = useCallback(position => {
    return system.panels.filter((x) => x.position === position)[0]?.sensorId;
  }, [system]);

  const state = useMemo(() => {
    const s = {
      system,
      setLocalSystem: setSystem,
      setRemoteSystem,
      edit,
      setEdit: setEditWithEffects,
      selected,
      setSelected,
      sensor,
      getSensor,
      resetSensor: () => setSensor(null)
    };

    return s;
  }, [system, setSystem, setRemoteSystem, edit, selected, setSelected, setEditWithEffects, sensor, getSensor]);

  return (
    <SystemContext.Provider value={state}>
      {children}
    </SystemContext.Provider>
  );
}

export function useSystemContext() {
  return useContext(SystemContext);
}