import { useEffect, useState } from 'react';
import { sampleService } from 'services';

export function useGetSample(sensorId) {
  const [state, setState] = useState(null);

  useEffect(() => {
    retriever();
    const dataRetriever = setInterval(() => {
      retriever();
    }, 10000);

    return () => {
      clearInterval(dataRetriever);
    };
  }, []);

  function retriever() {
    if (!sensorId) {
      return setState(undefined);
    }
    sampleService
      .getLive(sensorId)
      .then((data) => {
        setState(data[0]);
      })
      .catch(() => setState(undefined));
  }

  return state;
}
