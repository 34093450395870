export function DefaultInput({ onChange, value, type, className, placeholder }) {
  return (
    <input
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      type={type}
      className={
        'appearance-none border border-opacity-20 border-black rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ' +
        className
      }
    />
  );
}
