import { defaultText } from './items/Text';
import { defaultArrows } from './items/Arrows';
import { defaultBox } from './items/Box';
import { defaultLine } from './items/Line';
import { defaultCurvedArrow } from './items/CurvedArrow';
import { defaultArrow } from './items/Arrow';
import { defaultSensor } from './items/Sensor';
import { defaultTube } from './items/Tube';

export const defaultItems = [
  defaultSensor,
  defaultArrow,
  defaultArrows,
  defaultCurvedArrow,
  defaultBox,
  defaultText,
  defaultTube,
  defaultLine,
];

export function getRenderFunction(item) {
  item.render =
    defaultItems.filter((elem) => elem.type === item.type)[0] &&
    defaultItems.filter((elem) => elem.type === item.type)[0].render;
  return item;
}

export function attachRenderFunctions(items) {
  items &&
    items.forEach((item) => {
      getRenderFunction(item);
    });

  return items;
}
