import { Route, Routes } from 'react-router-dom';
import { AddEditUser } from 'components/Modules/addedit/AddEditUser';
import { UserTable } from 'components/Modules/table/UserTable';
import { Widget } from '../components/Widget';

export function Users() {
  return (
    <Widget>
      <Routes>
        <Route index path={'/'} element={<UserTable />} />
        <Route path={'add'} element={<AddEditUser />} />
        <Route path={':id/edit'} element={<AddEditUser />} />
      </Routes>
    </Widget>
  );
}