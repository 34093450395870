import { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { accountService } from 'services';
import toast from 'react-hot-toast';
import { FormGroup, FormInput, FormContainer, FormSubmit } from 'components/Elements/forms';
import { AddEditHeader } from 'components/Modules/addedit/AddEditHeader';
import { useNavigate } from 'react-router';
import { Widget } from 'screens/dashboard/components/Widget';

export function UpdateProfile() {
  const navigate = useNavigate();
  const user = accountService.userValue;
  const phoneRegExp = /^\+47{1}\d{8}$/;
  const initialValues = {
    firstName: user ? user.firstName : '',
    lastName: user ? user.lastName : '',
    email: user ? user.email : '',
    phone: user ? user.phone : '',
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Skriv inn fornavn'),
    lastName: Yup.string().required('Skriv inn etternavn'),
    email: Yup.string().email('Ugyldig e-postadresse').required('Skriv inn e-post'),
    phone: Yup.string().required().matches(phoneRegExp, 'Telefonnummmer er ikke gyldig, husk landskode "+47"'),
    password: Yup.string().min(6, 'Bruk seks eller flere tegn'),
    confirmPassword: Yup.string()
      .when('password', (password, schema) => {
        if (password) return schema.required('Skriv inn passord');
      })
      .oneOf([Yup.ref('password')], 'Passordene samsvarer ikke'),
  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    accountService
      .update(user.id, fields)
      .then(() => {
        toast.success('Oppdatering vellykket');
        // navigate.push(-1);
      })
      .catch((error) => {
        setSubmitting(false);
        toast.error(error);
      });
  }

  const [isDeleting, setIsDeleting] = useState(false);
  function onDelete() {
    if (window.confirm('Er du sikker?')) {
      setIsDeleting(true);
      accountService.delete(user.id).then(() => toast.success('Kontoen ble slettet'));
    }
  }

  return (
    <Widget>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
        {({ errors, touched, values, isSubmitting }) => {
          return (
            <Form>
              <FormInput name="firstName" title="Fornavn" type="text" errors={errors.firstName} touched={touched.firstName} value={values.firstName} />
              <FormInput name="lastName" title="Etternavn" type="text" errors={errors.lastName} touched={touched.lastName} value={values.lastName} />
              <FormInput name="email" title="E-post" type="email" errors={errors.email} touched={touched.email} value={values.email} />
              <FormInput name="phone" title="Mobil" type="tel" errors={errors.phone} touched={touched.phone} value={values.phone} />
              <div className="w-full mb-2 mt-6">
                <p className="text-md font-bold">Endre passord?</p>
                <p className="text-md">La feltene være tomme for å beholde eksisterende passord</p>
              </div>
              <FormInput name="password" title="Passord" type="password" errors={errors.password} touched={touched.password} value={values.password} placeholder="Nytt passord" />
              <FormInput name="confirmPassword" title="Gjenta passord" type="password" errors={errors.confirmPassword} touched={touched.confirmPassword} value={values.confirmPassword} placeholder="Gjenta nytt passord" />
              <FormSubmit title="Lagre" />
            </Form>
          );
        }}
      </Formik>
    </Widget>
  );
}
