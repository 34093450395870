import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { accountService, customerService } from 'services';
import toast from 'react-hot-toast';
import { FormInput, FormSelect, FormSubmit } from 'components/Elements/forms';
import { AddEditHeader } from './AddEditHeader';
import { useNavigate, useParams } from 'react-router';
import { hasAccess, Role, RoleName } from 'utils';
import { Button } from 'components/Elements/Button';

export function AddEditUser() {
  const { id } = useParams();
  const navigate = useNavigate();
  const isAddMode = !id;
  const account = accountService.userValue;
  const [customers, setCustomers] = useState(null);
  const [user, setUser] = useState(null);
  const initialValues = {
    firstName: user ? user.firstName : '',
    lastName: user ? user.lastName : '',
    email: user ? user.email : '',
    phone: user?.phone ? user.phone : '',
    role: user ? user.role : 4,
    customerId: user ? user.customerId : null,
    password: '',
    confirmPassword: '',
  };

  const availableRoles = Object.keys(RoleName).filter(role => role >= account.role).map(role => ({ id: role, title: RoleName[role]}));

  useEffect(() => {
    if (hasAccess(account, Role.Editor)) {
      customerService.getAll().then((x) => setCustomers(x));
    }

    if (!isAddMode) {
      // get user and set form fields
      accountService.getById(id).then((user) => {
        setUser(user);
      });
    }
  }, [account, id, isAddMode]);
  
  const phoneRegExp = /^\+47{1}\d{8}$/;

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Skriv inn fornavn'),
    lastName: Yup.string().required('Skriv inn etternavn'),
    email: Yup.string().email('Ugyldig e-postadresse').required('Skriv inn e-post'),
    phone: Yup.string().matches(phoneRegExp, 'Telefonnummmer er ikke gyldig, husk landskode "+47"').notRequired(),
    password: Yup.string()
      .concat(isAddMode ? Yup.string().required('Skriv inn passord') : null)
      .min(6, 'Bruk seks eller flere tegn'),
    confirmPassword: Yup.string()
      .when('password', (password, schema) => {
        if (password) return schema.required('Skriv inn passord');
      })
      .oneOf([Yup.ref('password')], 'Passordene samsvarer ikke'),
  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    if (isAddMode) {
      createUser(fields, setSubmitting);
    } else {
      updateUser(id, fields, setSubmitting);
    }
  }

  function createUser(fields, setSubmitting) {
    accountService
      .create(fields)
      .then(() => {
        toast.success('Brukeren ble lagt til');
        navigate(-1);
      })
      .catch((error) => {
        setSubmitting(false);
        toast.error(error);
      });
  }

  function updateUser(id, fields, setSubmitting) {
    accountService
      .update(id, fields)
      .then(() => {
        toast.success('Oppdatering vellykket');
        navigate(-1);
      })
      .catch((error) => {
        setSubmitting(false);
        toast.error(error);
      });
  }

  function removeUser() {
    if (!window.confirm('Er du sikker?')) return;
    accountService
      .delete(id)
      .then(() => {
        toast.success('Brukeren ble slettet');
        navigate(-1);
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
        {({ errors, touched, isSubmitting, values }) => {
          return (
            <Form>
              <AddEditHeader title="bruker" isAddMode={isAddMode} />
              <FormInput name="firstName" title="Fornavn" type="text" errors={errors.firstName} touched={touched.firstName} vaule={values.firstName} />
              <FormInput name="lastName" title="Etternavn" type="text" errors={errors.lastName} touched={touched.lastName} vaule={values.lastName} />
              <FormInput name="email" title="E-post" type="email" errors={errors.email} touched={touched.email} vaule={values.email} />
              <FormInput name="phone" placeholder="+47" title="Tlf" type="text" errors={errors.phone} touched={touched.phone} vaule={values.phone} />
              <FormSelect name="role" title="Rolle" errors={errors.role} touched={touched.role} vaule={values.role}>
                {availableRoles.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.title}
                  </option>
                ))}
              </FormSelect>
              {hasAccess(account, Role.Editor) && values.role >= 3 && (
                <FormSelect name="customerId" title="kunde" errors={errors.customerId} touched={touched.customerId} value={values.customerId}>
                  {customers &&
                      customers.map((customer) => (
                        <option key={customer.id} value={customer.id}>
                          {customer.orgName}
                        </option>
                      ))}
                </FormSelect>
              )}
              {!isAddMode && (
                <div className="mt-6 mb-2">
                  <p className="text-md font-bold">Endre passord</p>
                  <p>La det være tomt for å beholde passordet</p>
                </div>
              )}
              <FormInput name="password" title="Passord" type="password" errors={errors.password} touched={touched.password} vaule={values.password} />
              <FormInput name="confirmPassword" title="Gjenta passord" type="password" errors={errors.confirmPassword} touched={touched.confirmPassword} vaule={values.confirmPassword} />
              <FormSubmit title="Lagre" />
            </Form>
          );
        }}
      </Formik>
      <div className="absolute right-5 top-5">
        <Button onClick={() => removeUser()} className="bg-red-500">
        Slett
        </Button>
      </div>
    </>
  );
}
