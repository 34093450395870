export const defaultBox = {
  r: 0,
  x: 150,
  y: 150,
  w: 150,
  h: 250,
  minH: 50,
  minW: 50,
  type: 'box',
  style: {
    fill: '#e6e6e6',
  },
  render: Box,
};

export function Box({ id, state, view }) {
  const position = {
    lt: { x: state.x - state.w / 2, y: state.y - state.h / 2 },
    rt: { x: state.x + state.w / 2, y: state.y - state.h / 2 },
    rb: { x: state.x + state.w / 2, y: state.y + state.h / 2 },
    lb: { x: state.x - state.w / 2, y: state.y + state.h / 2 },
  };

  return (
    <>
      {position && (
        <g key={id} cursor={view ? 'default' : !state.disabled ? 'move' : 'pointer'}>
          <polygon
            id={id}
            className="item"
            points={`${position.lt.x},${position.lt.y} ${position.rt.x},${position.rt.y} ${position.rb.x},${position.rb.y} ${position.lb.x},${position.lb.y}`}
            fill={state.style.fill}
            stroke="black"
            strokeWidth="15"
            transform={`rotate(${state.r} ${state.x} ${state.y})`}
            // stroke={selected === id ? "#77c9f2" : "black"}
            // strokeDasharray={selected === id ? "15, 15" : "0"}
            // strokeWidth={selected === id ? 20 : 15}
            strokeLinejoin="round"
            cursor={view ? 'default' : !state.disabled ? 'pointer' : 'pointer'}
          ></polygon>
          <polygon
            id={id}
            className="item"
            points={`${position.lt.x},${position.lt.y} ${position.rt.x},${position.rt.y} ${position.rb.x},${position.rb.y} ${position.lb.x},${position.lb.y}`}
            fill={state.style.fill}
            stroke="#e6e6e6"
            strokeWidth={13}
            strokeLinejoin="round"
            transform={`rotate(${state.r} ${state.x} ${state.y})`}
            cursor={view ? 'default' : !state.disabled ? 'pointer' : 'pointer'}
          ></polygon>
        </g>
      )}
    </>
  );
}
