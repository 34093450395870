import { useState, useEffect, useCallback } from 'react';
import { Modal } from 'components/Modules/Modal';
import { Form, Formik } from 'formik';
import { systemService } from 'services';
import ImageViewer from 'react-simple-image-viewer';
import toast from 'react-hot-toast';
import { useSystemContext } from './context';

export function InfoModal() {
  const { system, setRemoteSystem } = useSystemContext();
  const [files, setFiles] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [info, setInfo] = useState('');

  useEffect(() => {
    if (system && system.files) {
      setFiles(system.files.filter((file) => file.removed !== true));
    }
    if (system && system.info) {
      setInfo(system.info);
    }
  }, [system]);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  // const validationSchema = Yup.object().shape({
  //   name: Yup.string().required("Skriv inn navn"),
  //   from: Yup.string().email("Ugyldig e-postadresse").required("Skriv inn e-post"),
  //   message: Yup.string().min(6, "Melding må være minst 6 tegn").max(500, "Melding må være under 500 tegn").required("Skriv inn melding"),
  // });

  function onSubmit(values, { resetForm }) {
    systemService
      .uploadFile(system.id, values)
      .then((x) => {
        setFiles([...files, x]);
        resetForm();
      })
      .catch((err) => toast.error(err));
  }

  function removeImage(fileId) {
    systemService
      .removeFile(fileId)
      .then(() => setFiles(files.filter((file) => file.id !== fileId)))
      .catch((err) => toast.error(err));
  }

  return (
    <>
      <div className="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-10 w-10 hover:text-blue-700 cursor-pointer"
          onClick={() => setIsOpen(true)}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} title="Systeminformasjon" size="xl">
        <div className="flex flex-col space-y-2">
          <div>
            <textarea
              className="flex-grow w-full h-48 border-2 rounded p-2"
              value={info}
              onChange={(e) => {
                setInfo(e.target.value);
              }}
            />
            {system && system.info !== info && !(!system.info && info === '') && (
              <button
                onClick={() => {
                  setRemoteSystem({ ...system, info: info });
                }}
                className="rounded border-2 border-green-500 px-3 py-2"
              >
                Lagre
              </button>
            )}
          </div>
          <p className="text-xl font-semibold">Bilder</p>
          <div className="grid grid-cols-4 gap-4">
            {files &&
              files.map((file, index) => (
                <div
                  key={index}
                  className="group bg-gray-300 rounded-lg h-36 xl:h-42 2xl:h-62 w-full flex flex-col flex-initial justify-center relative cursor-pointer shadow-md tracking-wide"
                >
                  <img
                    onClick={() => openImageViewer(index)}
                    className="object-cover h-full rounded group-hover:opacity-30"
                    src={file.url}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 hover:text-red-500 cursor-pointer absolute top-0 right-0 mr-1 opacity-0 group-hover:opacity-100"
                    onClick={() => removeImage(file.id)}
                    fill="none"
                    viewBox="0 0 20 20"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-12 w-12 cursor-pointer absolute top-1/2 left-1/2 m-auto opacity-0 hover:text-blue-500 group-hover:opacity-100 transform -translate-x-1/2 -translate-y-1/2"
                    onClick={() => openImageViewer(index)}
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7"
                    />
                  </svg>
                </div>
              ))}
            <Formik initialValues={{ file: null }} onSubmit={onSubmit}>
              {({ errors, touched, setFieldValue, values }) => (
                <Form>
                  <div className="w-full h-36 xl:h-42  2xl:h-62">
                    {!values['file'] ? (
                      <label className="h-full w-full flex flex-col justify-center text-center px-2 py-4 bg-white rounded-md shadow-md tracking-wide border border-blue cursor-pointer hover:bg-purple-600 hover:text-white text-purple-600 ease-linear transition-all duration-150">
                        <span className="text-base leading-normal">Legg til bilde</span>
                        <input
                          className="hidden"
                          type="file"
                          name="file"
                          onChange={(e) => setFieldValue('file', e.target.files[0])}
                        />
                      </label>
                    ) : (
                      <div className="h-full w-full flex flex-col px-2 rounded-md shadow-md tracking-wide border border-blue cursor-pointer ease-linear transition-all duration-150 justify-end text-center relative">
                        <img
                          alt="UploadedImage"
                          src={URL.createObjectURL(values['file'])}
                          className="absolute left-0 top-0 h-full w-full object-cover rounded"
                        />
                        <div className="z-10">
                          {/* <p>{values["file"].name}</p> */}
                          <div className="flex justify-center space-x-1">
                            <button
                              type="submit"
                              className="py-1 bg-green-500 bg-opacity-70 px-3 rounded-full hover:bg-opacity-100 mb-1"
                            >
                              Last opp
                            </button>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-8 w-8 hover:text-red-500 cursor-pointer rounded-full bg-white bg-opacity-70"
                              onClick={() => setFieldValue('file', null)}
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {isViewerOpen && (
          <ImageViewer
            src={files.map((file) => {
              return file.url;
            })}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: 'rgba(0,0,0,0.9)',
            }}
            closeOnClickOutside={true}
          />
        )}
      </Modal>
    </>
  );
}
