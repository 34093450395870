import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { customerService } from 'services';
import toast from 'react-hot-toast';
import { FormGroup, FormContainer, FormInput, FormSubmit, FormSearchAutocomplete } from 'components/Elements/forms';
import { AddEditHeader } from './AddEditHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'components/Elements/Button';

export function AddEditLocation() {
  const navigate = useNavigate();
  const customer = customerService.customerValue;
  const { locationId, customerId: customerIdParam } = useParams();
  const [location, setLocation] = useState(null);
  const isAddMode = !locationId;

  const customerId = customer?.id || customerIdParam;

  const initialValues = {
    title: location ? location.title : '',
    address: location ? location.address : '',
  };

  const locationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Skriv inn tittel'),

    address: Yup.string()
      .required('Skriv inn adresse'),
  });

  // 

  useEffect(() => {
    if (!isAddMode) {
      if (customerId) {
        customerService.getById(customerId).then((c) => {
          setLocation(c.locations.filter((loc) => loc.id === parseInt(locationId))[0]);
        });
      } else {
        setLocation(customer.locations.filter((loc) => loc.id === parseInt(locationId))[0]);
      }
    }
  }, [customerId, locationId, isAddMode, customer?.locations]);


  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    if (isAddMode) {
      addLocation(fields, setSubmitting);
    } else {
      updateLocation(fields, setSubmitting);
    }
  }

  function addLocation(fields, setSubmitting) {
    fields.customerId = customerId;
    console.log(fields.customerId);
    const params = { ...fields, customerId };
    customerService
      .addLocation(params)
      .then(() => {
        toast.success('Lokasjonen ble lagt til');
        navigate(-2);
      })
      .catch((error) => {
        setSubmitting(false);
        toast.error(error);
      });
  }

  function updateLocation(fields, setSubmitting) {
    customerService
      .updateLocation(locationId, fields)
      .then(() => {
        toast.success('Oppdatering vellykket');
        navigate(-1);
      })
      .catch((error) => {
        setSubmitting(false);
        toast.error(error);
      });
  }

  function deleteLocation() {
    if (!window.confirm('Er du sikker?')) return;
    customerService
      .removeLocation(locationId)
      .then(() => {
        toast.success('Lokasjonen ble slettet');
        navigate(-1);
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  return (
    <Formik initialValues={initialValues} validationSchema={locationSchema} onSubmit={onSubmit} enableReinitialize>
      {({ errors, touched, setFieldValue }) => {
        return (
          <div className="contain">
            <FormGroup>
              <AddEditHeader title="lokasjon" isAddMode={isAddMode} />
              <FormContainer>
                <FormInput name="title" title="tittel" type="text" errors={errors.title} touched={touched.title} />
                <FormSearchAutocomplete 
                  name='address' 
                  title='addresse'
                  queryUrl='https://ws.geonorge.no/adresser/v1/sok?sok=' 
                  dataFilter='adresser'
                  dataDropdownDisplay={['adressetekst', 'postnummer', 'poststed']}
                  clickFunction={e => setFieldValue('address', e)}/>
                <FormSubmit />
                
              </FormContainer>
            </FormGroup>
            <div className="absolute right-5 top-5">
              <Button onClick={() => deleteLocation()} className="bg-red-500">
                  Slett
              </Button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}
