import { useMemo, useEffect, useState } from 'react';
import { ViewWrapper } from 'components/Containers/ViewWrapper';
import { sensorService } from 'services';
import Table, { StatusPill } from 'components/Modules/table/Table';
import toast from 'react-hot-toast';
import { ToggleSwitch } from 'components/Modules/ToggleSwitch';
import { AddEditRule } from 'screens/system/modal/AddEditRule';
import { Loading } from 'components/Modules/Loading';

function RuleContainer({ value, row }) {
  const [rules, setRules] = useState(value);
  const sensor = row.original;

  return (
    <>
      {rules.map(rule => (
        <AddEditRule key={rule.id} sensor={sensor} rule={rule} rules={rules} setRules={setRules} isLocked />
      ))}
    </>
  );
}

function NotificationSwitches({ row }) {
  const { id, subscription } = row.original;
  function subscribeTo(sensorId, { email, sms }) {
    sensorService.subscribeTo(sensorId, email, sms).catch(() => toast.error('Cannot subscribe!'));
  }

  return (
    <div className="flex space-x-2">
      <ToggleSwitch
        initialValue={subscription?.email}
        onClick={(val) => subscribeTo(id, { email: val, sms: null })}
        title="Epost"
      />
      <ToggleSwitch
        initialValue={subscription?.sms}
        onClick={(val) => subscribeTo(id, { email: null, sms: val })}
        title="SMS"
      />
    </div>
  );
}

export function Notifications() {
  const [state, setState] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const sensors = await sensorService.getAll();
      const subscriptions = await sensorService.getSubscription();

      const attached = sensors.map(sensor => {
        const subscription = subscriptions.filter(x => x.sensorId === sensor.id)[0];
        return {
          ...sensor,
          subscription: subscription && subscription
        };
      }).filter(elem => elem.rules.length >= 1);

      setState(attached);
    }

    fetchData();
  }, []);

  const sensorColumns = useMemo(
    () => [
      {
        Header: 'Lokasjon',
        accessor: 'device.system.location.title',
      },
      {
        Header: 'System',
        accessor: 'device.system.title',
      },
      {
        Header: 'Tag',
        accessor: 'tag',
      },
      {
        Header: 'Alarm',
        accessor: 'rules',
        Cell: RuleContainer
      },
      {
        Header: 'Notifikasjoner',
        accessor: 'subscription',
        disableSortBy: true,
        Cell: ({ value, row }) => NotificationSwitches({ value, row })
      },
      {
        Header: 'Status',
        accessor: '!status',
        Cell: StatusPill,
      },
    ],
    []
  );

  return (
    <div className="contain">
      <section id="details">
        <ViewWrapper>
          {state ? <Table columns={sensorColumns} data={state} /> : <Loading />}
        </ViewWrapper>
      </section>
    </div>
  );
}
