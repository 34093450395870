import { useMemo, useState, useEffect } from 'react';
import { accountService, customerService } from 'services';
import Table, { SelectColumnFilter } from './Table';
import { ContentContainer } from 'components/Containers/ContentContainer';
import { useNavigate } from 'react-router';
import { Loading } from '../Loading';
import { useParams } from 'react-router-dom';
import { Role, RoleName } from 'utils';

export function UserTable({ filter }) {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const [users, setUsers] = useState(null);
  const customer = customerService.customerValue;

  const userColumns = useMemo(
    () => [
      {
        Header: 'Fornavn',
        accessor: 'firstName',
      },
      {
        Header: 'Etternavn',
        accessor: 'lastName',
      },
      {
        Header: 'E-post',
        accessor: 'email',
      },
      {
        Header: 'Tlf',
        accessor: 'phone',
      },
      {
        Header: 'Rolle',
        accessor: 'role',
        Filter: SelectColumnFilter,
        filter: 'includes',
        Cell: ({ value }) => {
          // return RoleName[value];
          // let res;
          // Object.keys(RoleName).forEach((key) => {
          //   if (RoleName[key] === value) {
          //     res = key;
          //   }
          // });
          return (
            <div className="text-sm text-gray-600 dark:text-white">{RoleName[value]}</div>
          );
        }
      },
      {
        Header: 'Kunde',
        accessor: 'customer.orgName',
        Filter: SelectColumnFilter,
        filter: 'includes',
        access: Role.Admin,

      },
    ],
    []
  );
  useEffect(() => {
    if (customer) {
      setUsers(customer.accounts);
    } else {
      accountService.getAll().then((x) => {
        setUsers(filter ? x.filter(elem => elem.customerId === customerId) : x);
      });
    }
  }, [customer, customerId, filter]);

  function deleteUser(id) {
    setUsers(
      users.map((x) => {
        if (x.id === id) {
          x.isDeleting = true;
        }
        return x;
      })
    );
    accountService.delete(id).then(() => {
      setUsers((users) => users.filter((x) => x.id !== id));
    });
  }

  function userClick(object) {
    navigate(`${object.id}/edit`);
  }

  function addClick() {
    navigate('add');
  }

  return (
    <ContentContainer>
      <div className="text-center mb-4">
        <h4>Brukere</h4>
        <p>Antall Brukere: {users && users.length}</p>
      </div>
      {users ? <Table columns={userColumns} data={users} handleClick={userClick} addButton={addClick} /> : <Loading />}
    </ContentContainer>
  );
}
