import config from 'config';
import { fetchWrapper } from 'utils';

const baseUrl = `${config.apiUrl}/subscriptions`;

export const subscriptionService = {  
  getById,
  subscribeTo,
};

function getById(sensorId = null) {
  return fetchWrapper.get(`${baseUrl}/${sensorId}`);
}

function subscribeTo(sensorId, email, sms) {
  return fetchWrapper.post(`${baseUrl}/${sensorId}&${email}&${sms}`);
}