import { useEffect, useState } from 'react';
import { customerService } from 'services';
import toast from 'react-hot-toast';
import { Weather } from '../MyDashboard/Weather';
import { GridController } from '../MyDashboard/GridController';
import ImagePicker from '../components/ImagePicker';
import ImageViewer from '../components/ImageViewer';
import { Widget } from '../components/Widget';
import { hasAccess, Role } from 'utils';
import { accountService } from 'services';


export function Overview() {
  const user = accountService.userValue;
  const customer = customerService.customerValue;
  const [files, setFiles] = useState(customer?.files);
  const [staleInfo, setStaleInfo] = useState(customer?.info);
  const [info, setInfo] = useState(customer?.info);
  const [timestamp, setTimestamp] = useState(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }));

  useEffect(() => {
    const watch = setInterval(() => {
      setTimestamp(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }));
    }, 1000);

    return () => clearInterval(watch);
  }, []);

  function onSubmit(values, { resetForm }) {
    customerService
      .uploadFile(customer.id, values)
      .then((x) => {
        setFiles([...files, x]);
        resetForm();
      })
      .catch((err) => toast.error(err));
  }

  function removeImage(fileId) {
    customerService
      .removeFile(fileId)
      .then(() => setFiles(files.filter((file) => file.id !== fileId)))
      .catch((err) => toast.error(err));
  }

  return (
    <>
      <Widget title="Hurtigtilgang" cols="3" rows="3">
        {/* TODO: IMPLEMENT GRIDVIEWER HERE */}
        <GridController 
          dashboardId={customer?.dashboards[0]?.id} 
          cards={customer?.dashboards[0]?.cards} 
          columns={4}
          canEdit={hasAccess(user, Role.CustomerAdmin)}
        />
      </Widget>

      <Widget cols="1">
        <p className="font-bold tracking-wide text-sky-600 text-4xl mb-5">{timestamp}</p>
        <p className="capitalize">
          {new Date().toLocaleString(window.navigator.language, {
            weekday: 'long',
          })}
        </p>
        <p>{new Date().toLocaleDateString()}</p>
      </Widget>

      <Widget cols="1">
        <Weather />
      </Widget>

      <Widget title="Kunngjøringer" cols="2" rows="2">
        <div className="relative mb-1">
          <textarea
            className={(hasAccess(user, Role.CustomerAdmin) ? 'p-2' : 'resize-none border-0 bg-white hover:cursor-text') + ' w-full h-auto border-2 rounded -mb-2'}
            value={info ? info : ''}
            onChange={(e) => {
              setInfo(e.target.value);
            }}
            rows={8}
            disabled={hasAccess(user, Role.CustomerAdmin) ? false : true}
          />
          {staleInfo !== info && !(!staleInfo && info === '') && (
            <button
              onClick={() => {
                customerService.update(customer.id, {
                  ...customer,
                  info: info,
                });
                setStaleInfo(info);
              }}
              className="absolute border-2 border-green-500 bottom-2 right-2 rounded px-3 py-2"
            >
                  Lagre
            </button>
          )}
        </div>
      </Widget>

      {files && <Widget cols="2">
        {hasAccess(user, Role.CustomerAdmin) && !files[0] ? (
          <ImagePicker onSubmit={onSubmit} />
        ) : (
          <ImageViewer url={files[0]?.url} onRemoveClick={() => removeImage(files[0].id)} canEdit={hasAccess(user, Role.CustomerAdmin)} />
        )}
      </Widget>}
    </>
  );
}
