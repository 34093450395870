import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { accountService, customerService } from 'services';
import toast from 'react-hot-toast';
import { FormGroup, FormContainer, FormInput, FormSubmit } from 'components/Elements/forms';
import { AddEditHeader } from './AddEditHeader';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';

export function EditCustomer() {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const isAddMode = !customerId;
  const [customer, setCustomer] = useState(null);
  const initialValues = {
    title: customer ? customer.title : '',
  };

  useEffect(() => {
    if (!isAddMode) {
      // get user and set form fields
      customerService.getById(customerId).then((customer) => {
        setCustomer(customer);
      });
    }
  }, []);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Skriv inn tittel'),
  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    if (isAddMode) {
      createCustomer(fields, setSubmitting);
    } else {
      updateCustomer(customerId, fields, setSubmitting);
    }
  }

  function createCustomer(fields, setSubmitting) {
    customerService
      .create(fields)
      .then(() => {
        toast.success('Kunden ble lagt til');
        navigate(-1);
      })
      .catch((error) => {
        setSubmitting(false);
        toast.error(error);
      });
  }

  function updateCustomer(customerId, fields, setSubmitting) {
    accountService
      .update(customerId, fields)
      .then(() => {
        toast.success('Oppdatering vellykket');
        navigate(-1);
      })
      .catch((error) => {
        setSubmitting(false);
        toast.error(error);
      });
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
      {({ errors, touched, isSubmitting }) => {
        return (
          <div className="contain">
            <FormGroup>
              <AddEditHeader title="kunde" isAddMode={isAddMode} />
              <FormContainer>
                <FormInput name="title" title="Tittel" type="text" errors={errors.title} touched={touched.title} />
                <FormSubmit />
              </FormContainer>
            </FormGroup>
          </div>
        );
      }}
    </Formik>
  );
}
