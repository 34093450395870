import config from 'config';
import { fetchWrapper } from 'utils';

const baseUrl = `${config.deviceApiUrl}/rules`;

export const ruleService = {
  addRule,
  updateRule,
  getRules,
  removeRule,
};

function addRule(params) {
  return fetchWrapper.post(`${baseUrl}`, params);
}

function updateRule(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params);
}

function getRules(sensorId) {
  const query = {
    sensorIds: [sensorId],
  };
  return fetchWrapper.get(`${baseUrl}/?${new URLSearchParams(query)}`);
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function removeRule(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}
