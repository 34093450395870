import { useEffect, useRef } from 'react';
import { ItemSelect } from './ItemSelect';
import { defaultItems } from './itemConfig';

export function ItemCanvas({ items, setItems, selected, setSelected, history, copyItem, removeItems }) {
  const svgRef = useRef();
  const itemChangeRef = useRef(false);
  const itemRef = useRef([]);

  useEffect(() => {
    window.addEventListener('pointerdown', pointerDownHandler);
    window.addEventListener('pointerup', pointerUpHandler);
    window.addEventListener('keydown', keyDownHandler);
    return () => {
      window.removeEventListener('pointerdown', pointerDownHandler);
      window.removeEventListener('pointerup', pointerUpHandler);
      window.removeEventListener('keydown', keyDownHandler);
    };

    // eslint-disable-next-line
  }, [items]);

  function pointerDownHandler(e) {
    if (e.target.classList.contains('item')) {
      const id = parseInt(e.target.id);
      if (isNaN(id)) return;
      selectItem(e, id);
    }
  }

  function pointerUpHandler(e) {
    if (e.target.classList[0] === 'item') {
      const id = parseInt(e.target.id);
      if (isNaN(id)) {
        addItem(e.target.id);
      }
    } else {
      if (e.target.id === 'BuilderCanvas') {
        setSelected([]);
      }
    }
  }

  function keyDownHandler(e) {
    switch (e.key) {
    case 'z':
      if (e.ctrlKey) {
        history.back();
      }
      break;
    case 'y':
      if (e.ctrlKey) {
        history.forward();
      }
      break;
    case 'c':
      if (e.ctrlKey) {
        copyItem();
      }
      break;
    case 'a':
      if (e.ctrlKey) {
        e.preventDefault();
        setSelected([
          ...items.map((item) => {
            return items.indexOf(item);
          }),
        ]);
      }
      break;
    case 'ArrowLeft':
      if (selected.length >= 1 && e.key !== 'a') {
        e.preventDefault();
        setItems(
          items.map((item) => {
            return {
              ...item,
              x: selected.includes(items.indexOf(item)) ? item.x - 1 : item.x,
            };
          })
        );
      }
      break;
    case 'ArrowUp':
      if (selected.length >= 1) {
        e.preventDefault();
        setItems(
          items.map((item) => {
            return {
              ...item,
              y: selected.includes(items.indexOf(item)) ? item.y - 1 : item.y,
            };
          })
        );
      }
      break;
    case 'ArrowRight':
      if (selected.length >= 1) {
        e.preventDefault();
        setItems(
          items.map((item) => {
            return {
              ...item,
              x: selected.includes(items.indexOf(item)) ? item.x + 1 : item.x,
            };
          })
        );
      }
      break;
    case 'ArrowDown':
      if (selected.length >= 1) {
        e.preventDefault();
        setItems(
          items.map((item) => {
            return {
              ...item,
              y: selected.includes(items.indexOf(item)) ? item.y + 1 : item.y,
            };
          })
        );
      }
      break;
    case 'Enter':
      setSelected([]);
      break;
    case 'Delete':
      removeItems();
      break;
    case 'd':
      if (selected.length >= 0 && e.ctrlKey) {
        e.preventDefault();
        setSelected([]);
      }
      break;
    case 'Escape':
      if (selected.length >= 0) {
        setSelected([]);
      }
      break;
    default:
      break;
    }
  }

  function repositionItem(ids, x, y) {
    setItems(
      items.map((item) => {
        return {
          ...item,
          x: ids.includes(items.indexOf(item)) ? Math.round(item.x - x) : item.x,
          y: ids.includes(items.indexOf(item)) ? Math.round(item.y - y) : item.y,
        };
      })
    );
  }

  function repositionAndResizeItem(id, x, y, w, h) {
    setItems(
      items.map((item) => {
        return {
          ...item,
          x: items.indexOf(item) === id ? Math.round(x) : item.x,
          y: items.indexOf(item) === id ? Math.round(y) : item.y,
          w: items.indexOf(item) === id ? Math.round(w) : item.w,
          h: items.indexOf(item) === id ? Math.round(h) : item.h,
        };
      })
    );
  }

  function eventListener(element, eventHandler, id = null) {
    element.addEventListener('mousemove', eventHandler, false);
    window.addEventListener(
      'mouseup',
      function endEventHandler() {
        if (id !== null && itemChangeRef.current !== true) {
          setSelected((selected) => {
            if (selected.includes(id)) {
              return [...selected.filter((item) => item !== id)];
            } else {
              return [...selected, id];
            }
          });
        }
        if (itemChangeRef.current === true) {
          history.add(items);
          history.setPointer(-1);
        }

        itemChangeRef.current = false;
        element.removeEventListener('mousemove', eventHandler, false);
        window.removeEventListener('mouseup', endEventHandler);
      },
      false
    );
  }

  function selectItem(e, id) {
    e.target.setPointerCapture(e.pointerId);
    itemRef.current[id] = e.target;

    var tmp;
    setSelected((selected) => {
      tmp = selected.map((id) => {
        return id;
      });
      return selected;
    });

    const origX = e.clientX;
    const origY = e.clientY;

    const viewbox = svgRef.current.getBoundingClientRect();
    const scaleX = viewbox.width / 1500;
    const scaleY = viewbox.height / 570;

    function eventMoveHandler(e) {
      itemChangeRef.current = true;
      if (tmp.length > 1 && tmp.includes(id)) {
        repositionItem(tmp, (origX - e.clientX) / scaleX, (origY - e.clientY) / scaleY);
      } else {
        repositionItem([id], (origX - e.clientX) / scaleX, (origY - e.clientY) / scaleY);
      }
    }

    eventListener(window, eventMoveHandler, id);
  }

  function resizeItem(e, id, direction) {
    if (direction.rotate) {
      return rotateItem(e, id);
    }

    e.target.setPointerCapture(e.pointerId);

    const mousePressX = e.clientX;
    const mousePressY = e.clientY;

    const item = items[id];

    const initRadians = (item.r * Math.PI) / 180;
    const cosFraction = Math.cos(initRadians);
    const sinFraction = Math.sin(initRadians);

    const minHeight = item.minH || 50,
      minWidth = item.minW || 50;

    const viewbox = svgRef.current.getBoundingClientRect();
    const scaleX = viewbox.width / 1500;
    const scaleY = viewbox.height / 570;

    const initW = item.w,
      initH = item.h,
      initX = item.x,
      initY = item.y;

    const { xResize, yResize, left, top } = direction;

    function eventMoveHandler(e) {
      itemChangeRef.current = true;

      var wDiff = (e.clientX - mousePressX) / scaleX;
      var hDiff = (e.clientY - mousePressY) / scaleY;
      var rotatedWDiff = cosFraction * wDiff + sinFraction * hDiff;
      var rotatedHDiff = cosFraction * hDiff - sinFraction * wDiff;

      var newW = initW,
        newH = initH,
        newX = initX,
        newY = initY;

      if (xResize) {
        if (left) {
          newW = initW - rotatedWDiff;
          if (newW < minWidth) {
            newW = minWidth;
            rotatedWDiff = initW - minWidth;
          }
        } else {
          newW = initW + rotatedWDiff;
          if (newW < minWidth) {
            newW = minWidth;
            rotatedWDiff = minWidth - initW;
          }
        }
        newX += 0.5 * rotatedWDiff * cosFraction;
        newY += 0.5 * rotatedWDiff * sinFraction;
      }

      if (yResize) {
        if (top) {
          newH = initH - rotatedHDiff;
          if (newH < minHeight) {
            newH = minHeight;
            rotatedHDiff = initH - minHeight;
          }
        } else {
          newH = initH + rotatedHDiff;
          if (newH < minHeight) {
            newH = minHeight;
            rotatedHDiff = minHeight - initH;
          }
        }
        newX -= 0.5 * rotatedHDiff * sinFraction;
        newY += 0.5 * rotatedHDiff * cosFraction;
      }
      repositionAndResizeItem(id, newX, newY, newW, newH);
    }

    eventListener(window, eventMoveHandler);
  }

  function rotateItem(e, id) {
    e.target.setPointerCapture(e.pointerId);

    const item = itemRef.current[id].getBoundingClientRect();
    const cx = item.left + item.width / 2;
    const cy = item.top + item.height / 2;

    function eventRotateHandler(e) {
      itemChangeRef.current = true;

      const dx = e.clientX - cx;
      const dy = e.clientY - cy;
      const rotation = Math.atan2(dy, dx);
      const r = Math.round((rotation * 180) / Math.PI) + 90;

      setItems(
        items.map((item) => {
          return {
            ...item,
            r: items.indexOf(item) === id ? (r < 0 ? Math.round(r + 360) : Math.round(r)) : item.r,
          };
        })
      );
    }

    eventListener(window, eventRotateHandler);
  }

  /**
   *
   * @param {string} type Item type as defined in item component defaults. Example: tube
   */
  function addItem(type) {
    const item = defaultItems.filter((elem) => elem.type === type)[0];
    setItems([...items, item]);

    // const newItems = [];
    // switch (type) {
    //  case "tube":
    //    newItems.push({ ...defaultTube, render: Tube });
    //    break;
    //  case "box":
    //    newItems.push({ ...defaultBox, render: Box });
    //    break;
    //  case "sensor":
    //    newItems.push({ ...defaultSensor, render: Sensor });
    //    break;
    //  case "arrows":
    //    newItems.push({ ...defaultArrows, render: Arrows });
    //    break;
    //  case "text":
    //    newItems.push({ ...defaultText, render: Text });
    //    break;
    //  case "arrow":
    //    newItems.push({ ...defaultArrow, render: Arrow });
    //    break;
    //  case "line":
    //    newItems.push({ ...defaultLine, render: Line });
    //    break;
    //  case "curvedArrow":
    //    newItems.push({ ...defaultCurvedArrow, render: CurvedArrow });
    //    break;
    //  default:
    //    break;
    // }
    // if (items) {
    //  setItems([...items, ...newItems]);
    // } else {
    //  setItems([{ ...newItems }]);
    // }
  }

  return (
    <>
      <div className="w-auto h-auto m-1">
        <svg
          ref={svgRef}
          id="BuilderCanvas"
          viewBox="0 0 1500 570"
          className="border-2 border-black border-opacity-10 rounded"
        >
          {/* <ItemRender items={items} /> */}
          {items && items.map((item, idx) => <g key={idx}>{item.render({ id: idx, state: item })}</g>)}
          {selected.length > 0 &&
            selected.map((item) => (
              <ItemSelect key={`ItemWrapper-${item}`} id={item} state={items[item]} resizeEvent={resizeItem} />
            ))}
        </svg>
      </div>
    </>
  );
}
