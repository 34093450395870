const ContactIcon = () => {
  return (
    // <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    //   <g> 
    //     <path stroke="null" fill="currentColor" id="svg_1" d="m142.7756,94.45351l343.69562,0a24.01728,24.01728 0 0 1 23.97588,23.97588l0,254.58321a23.85165,23.85165 0 0 1 -4.14091,13.45796a9.60691,9.60691 0 0 1 -2.2775,3.39555a9.11,9.11 0 0 1 -2.23609,1.57355a23.93447,23.93447 0 0 1 -15.32137,5.59023l-203.36014,0a63.93567,63.93567 0 0 0 1.73918,-18.42705l195.53382,0l-127.7057,-134.12411l-42.15448,35.94311l0,0a9.19282,9.19282 0 0 1 -11.76019,0l-44.22493,-35.94311l0,0l-40.12543,40.20825a51.3473,51.3473 0 0 0 -18.26142,-7.7435l44.05929,-44.14211l-81.70018,-66.62726a57.97276,57.97276 0 0 0 -2.81582,-14.16192l-3.27132,-14.45178l152.01285,123.93747l174.37377,-148.57589l-331.89402,0l-4.14091,-18.46846zm-68.94617,115.94551a254.5004,254.5004 0 0 0 19.58651,61.74098a214.87188,214.87188 0 0 0 43.14829,60.33307a6.08714,6.08714 0 0 0 4.84487,1.86341a21.98824,21.98824 0 0 0 8.28182,-2.77441a74.16372,74.16372 0 0 0 8.69591,-5.63164c13.00246,-9.02719 28.98638,-20.24906 42.60998,-7.66069c0.33127,0.28986 0.49691,0.57973 0.82818,0.82818l43.35534,47.74471a4.67923,4.67923 0 0 1 0.41409,0.53832a25.21815,25.21815 0 0 1 3.27132,23.89306a59.25644,59.25644 0 0 1 -16.31519,23.85165a56.97894,56.97894 0 0 1 -32.87883,14.32755a123.39915,123.39915 0 0 1 -34.28674,-1.65636a112.46715,112.46715 0 0 1 -46.8337,-19.58651a224.27175,224.27175 0 0 1 -40.82938,-39.04879l-0.99382,-1.20086c-6.29418,-7.45364 -13.08528,-15.4456 -19.42087,-24.34856a325.97252,325.97252 0 0 1 -51.63716,-119.38247c-7.12237,-36.23297 -5.67305,-72.42454 9.64832,-102.36332c8.28182,-16.56364 20.95301,-29.81456 37.2682,-35.98452c14.24473,-5.466 31.09824,-5.54882 50.22925,2.60877a6.91532,6.91532 0 0 1 4.14091,5.13473l15.4456,68.65631a18.92396,18.92396 0 0 1 -3.39555,20.20765a39.04879,39.04879 0 0 1 -17.76451,10.35228c-2.31891,0.99382 -5.0105,1.86341 -7.86773,2.77441c-9.39987,3.06427 -20.12483,6.66687 -19.58651,15.11433l0,0l0.04141,-0.33127zm292.84523,22.07106l125.30397,131.39111l0,-238.01957l-125.30397,106.75269l0,-0.12423z"/>
    //   </g>
    // </svg>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
    </svg>
  );
};

export default ContactIcon;