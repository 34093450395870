export const defaultArrows = {
  r: 0,
  x: 150,
  y: 150,
  w: 70,
  h: 85,
  type: 'arrows',
  render: Arrows,
};

export function Arrows({ id, state, selected, externalEvent, view }) {
  const position = {
    lt: { x: state.x - state.w / 2, y: state.y - state.h / 2 },
    lm: { x: state.x + state.w / 2 - 40, y: state.y },
    lb: { x: state.x - state.w / 2, y: state.y + state.h / 2 },
    rt: { x: state.x - state.w / 2 + 40, y: state.y - state.h / 2 },
    rm: { x: state.x + state.w / 2, y: state.y },
    rb: { x: state.x - state.w / 2 + 40, y: state.y + state.h / 2 },
  };

  return (
    <>
      {position && position.lt.x && (
        <>
          <path
            id={id}
            d={
              'M' +
              position.lt.x +
              ',' +
              position.lt.y +
              ' L ' +
              position.lm.x +
              ',' +
              position.lm.y +
              ' L ' +
              position.lb.x +
              ',' +
              position.lb.y
            }
            fill="none"
            className="item"
            stroke={selected === id ? '#77c9f2' : 'black'}
            strokeWidth="6"
            cursor={view ? 'default' : 'pointer'}
            pointerEvents="bounding-box"
            transform={`rotate(${state.r} ${state.x} ${state.y})`}
          />
          <path
            id={id}
            d={
              'M' +
              position.rt.x +
              ',' +
              position.rt.y +
              ' L ' +
              position.rm.x +
              ',' +
              position.rm.y +
              ' L ' +
              position.rb.x +
              ',' +
              position.rb.y
            }
            fill="none"
            className="item"
            stroke={selected === id ? '#77c9f2' : 'black'}
            strokeWidth="6"
            cursor={view ? 'default' : 'pointer'}
            pointerEvents="bounding-box"
            transform={`rotate(${state.r} ${state.x} ${state.y})`}
          />
        </>
      )}
    </>
  );
}
