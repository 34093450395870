import { DefaultInput } from 'components/Elements/Input';
import { useEffect, useState } from 'react';
import { sensorService } from 'services';
import { useSystemContext } from '../../context';

export default function SensorTag() {
  const { selected, system, setLocalSystem, sensor } = useSystemContext();
  const [state, setState] = useState('');

  useEffect(() => {
    setState(sensor?.tag || '');
  }, [sensor]);

  function update(e) {
    setState(e.target.value);
    const res = { ...system };
    res.panels.filter((x) => x.position === selected)[0].sensor.tag = e.target.value;
    setLocalSystem(res);
    sensorService.updateSensor(sensor.id, { tag: e.target.value });
  }

  return (
    <div className="">
      <p className="font-semibold">Tag</p>
      <form>
        <DefaultInput onChange={update} value={state} placeholder={sensor.tag} type="text" />
      </form>
    </div>
  );
}