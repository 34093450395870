const LocationIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
      <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
    </svg>
  //   <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
  //   <g>
  //     <path fill="currentColor" stroke="null" d="m257.83326,474.70783a336.98882,336.98882 0 0 0 64.98319,-55.39499a355.75113,355.75113 0 0 0 43.95922,-59.66349l26.27997,18.88972a386.74556,386.74556 0 0 1 -46.38016,62.49854a367.09133,367.09133 0 0 1 -79.28586,65.81141a16.18209,16.18209 0 0 1 -18.22078,0.63709a449.65821,449.65821 0 0 1 -110.66255,-98.74897c-40.55079,-50.71237 -66.00254,-106.96743 -74.76252,-161.21566c-8.91926,-55.33128 -0.50967,-108.5283 27.10818,-152.10527a188.61054,188.61054 0 0 1 41.88868,-46.38016c39.08548,-31.08999 83.45881,-47.3995 127.80028,-47.01726c42.84431,0.3504 85.1471,16.27765 121.71607,49.62933a179.05418,179.05418 0 0 1 32.42788,39.56329c27.45858,45.32897 35.03995,101.93442 25.64287,160.19632l-29.59284,-21.11953c4.58704,-45.07413 -2.64393,-87.98215 -23.47678,-122.41686a148.12345,148.12345 0 0 0 -26.63037,-32.36418c-30.42105,-27.74528 -65.23803,-40.99675 -100.24613,-41.41086c-36.95123,-0.22298 -74.41213,13.793 -107.66823,40.20039a154.94032,154.94032 0 0 0 -34.56214,38.44839c-23.25379,36.56897 -30.26178,82.05721 -22.58484,129.74341c7.86806,48.76925 31.08999,99.76832 68.04123,146.14848a415.51018,415.51018 0 0 0 94.25748,86.00717l-0.03186,0.0637zm-31.85451,-379.06862a120.76043,120.76043 0 0 1 143.66383,156.40562l82.02535,58.48487l-26.8852,40.77377l-79.76368,-57.40182a120.72857,120.72857 0 1 1 -119.04029,-198.13502l0,-0.12742l-0.00001,0zm78.36209,35.54963a95.02199,95.02199 0 1 0 43.44955,58.35745a94.79901,94.79901 0 0 0 -43.44955,-58.35745z" id="svg_2"/>
  //   </g>
  // </svg>
  );
};

export default LocationIcon;