import SystemType from './SystemType';
import Sensor from './sensor';

function Editor() {

  return (
    <>
      <div className="ml-2 mr-2 border-t border-b">
        <div className="flex space-x-2">
          <SystemType />
          <Sensor />
        </div>
      </div>
    </>
  );
}

export { Editor };
