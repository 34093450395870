export const defaultLine = {
  r: 0,
  x: 150,
  y: 150,
  w: 250,
  h: 80,
  minH: 10,
  minW: 10,
  type: 'line',
  style: {
    fill: 'black',
  },
  render: Line,
};

export function Line({ id, state, selected, view }) {
  const position = {
    l: { x: state.x - state.w / 2, y: state.y },
    r: { x: state.x + state.w / 2, y: state.y },
  };

  return (
    <>
      {position && position.l.x && (
        <line
          id={id}
          className="item"
          x1={position.l.x}
          y1={position.l.y}
          x2={position.r.x}
          y2={position.r.y}
          stroke={state.style.fill}
          strokeWidth={state.h / 5}
          cursor={view ? 'default' : 'pointer'}
          transform={`rotate(${state.r} ${state.x} ${state.y})`}
          //pointerEvents="bounding-box"
        />
      )}
    </>
  );
}
