import { BehaviorSubject } from 'rxjs';

import config from 'config';
import { fetchWrapper } from 'utils';

const customerSubject = new BehaviorSubject(null);
const baseUrl = `${config.apiUrl}/customers`;

export const customerService = {
  getStatus,
  login,
  logout,
  getAll,
  getById,
  create,
  update,
  addLocation,
  addCategory,
  addSystem,
  updateLocation,
  updateCategory,
  updateSystem,
  delete: _delete,
  removeLocation,
  removeCategory,
  removeSystem,
  getCategories,
  getSystem,
  customerSubject,
  customer: customerSubject.asObservable(),
  get customerValue() {
    return customerSubject.value;
  },
};

customerService.getForecast = (customerId) => {
  return fetchWrapper.get(`${baseUrl}/forecast/${customerId}`);
};

customerService.getAllAlarms = (limit) => {
  return fetchWrapper.get(`${baseUrl}/alarms/&${limit}`);
};

customerService.uploadFile = (customerId, file) => {
  let formData = new FormData();
  formData.append('file', file.file);
  return fetchWrapper.postFormData(`${baseUrl}/file/${customerId}`, formData);
};

customerService.removeFile = (fileId) => {
  return fetchWrapper.delete(`${baseUrl}/file/${fileId}`);
};

customerService.notify = (params) => {
  return fetchWrapper.post(`${baseUrl}/notify`, params);
};

function getSystem(id) {
  return fetchWrapper.get(`${baseUrl}/system/${id}`);
}

function getCategories() {
  return fetchWrapper.get(`${baseUrl}/categories`);
}

function getStatus(id) {
  return fetchWrapper.get(`${baseUrl}/status/${id}`);
}

function login(id) {
  return getById(id).then((customer) => {
    customerSubject.next(customer);
    return customer;
  });
}

function logout() {
  // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page
  customerSubject.next(null);
}

function getAll() {
  return fetchWrapper.get(baseUrl);
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function create(params) {
  return fetchWrapper.post(baseUrl, params);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params).then((customer) => {
    customer = { ...customerSubject.value, ...customer };
    customerSubject.next(customer);
    return customer;
  });
}

function addLocation(params) {
  return fetchWrapper.post(`${baseUrl}/location`, params);
}

function addCategory(params) {
  return fetchWrapper.post(`${baseUrl}/category`, params);
}

function addSystem(params) {
  return fetchWrapper.post(`${baseUrl}/system`, params);
}

function updateLocation(location, params) {
  return fetchWrapper.put(`${baseUrl}/location/${location}`, params).then((location) => {
    return location;
  });
}

function updateCategory(category, params) {
  return fetchWrapper.put(`${baseUrl}/category/${category}`, params).then((location) => {
    return location;
  });
}

function updateSystem(id, params) {
  return fetchWrapper.put(`${baseUrl}/system/${id}`, params).then((location) => {
    return location;
  });
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`).then((x) => {
    // auto logout if the logged in user deleted their own record
    return x;
  });
}

function removeLocation(id) {
  return fetchWrapper.delete(`${baseUrl}/location/${id}`);
}

function removeCategory(id) {
  return fetchWrapper.delete(`${baseUrl}/category/${id}`);
}

function removeSystem(id) {
  return fetchWrapper.delete(`${baseUrl}/system/${id}`);
}
