import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import toast from 'react-hot-toast';
import { deviceService } from 'services';
import { FormGroup, FormInput, FormSubmit } from 'components/Elements/forms';
import { useNavigate, useParams } from 'react-router';

export function DeviceConfig() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [device, setDevice] = useState(null);

  const initialValues = {
    title: device ? device.title : '',
    description: device?.description > 0 ? device.description : '',
    interval: device ? device.interval : '',
  };

  useEffect(() => {
    deviceService.getById(id).then((device) => {
      setDevice(device);
    });
  }, [id]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Tittel er påkrevd'),
    description: Yup.string(),
    interval: Yup.number().required('Intervall er påkrevd'),
  });

  async function onSubmit(fields, { setSubmitting }) {
    setSubmitting(true);
    const res = await deviceService.updateDevice(id, fields);
    if (res) {
      toast.success('Oppdatering vellykket');
    } else {
      toast.error('Oppdatering feilet');
    }
    setSubmitting(false);
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
      {({ errors, touched, isSubmitting }) => {
        return (
          <Form className="contain">
            <FormGroup>
              <FormInput name="title" title="Tittel" type="text" errors={errors.title} touched={touched.title} />
              <FormInput name="interval" title="Skriveintervall (ms)" type="number" errors={errors.interval} touched={touched.interval} />
              <FormInput name="description" title="Beskrivelse" type="textarea" errors={errors.description} touched={touched.description} />
              <FormSubmit title="Lagre" />
            </FormGroup>
          </Form>
        );
      }}
    </Formik>
  );
}