import { classNames } from 'utils/classNames';

export function WidgetGrid( {children} ) {
  return (
    <div className="grid grid-cols-4 lg:grid-cols-24 gap-7 md:m-7 auto-cols-min">
      {children}
    </div>
  );
}

export function Widget( props ) {
  var classes;

  if (props.cols) {
    if (props.cols === '0') {
      classes = 'col-span-full';
    }else if (props.cols === '1') {
      classes = 'col-span-full xs:col-span-full sm:col-span-2 md:col-span-2 lg:col-span-6 xl:col-span-4 2xl:col-span-6 3xl:col-span-3';
    } else if (props.cols === '2') {
      classes = 'col-span-full xs:col-span-full sm:col-span-full md:col-span-full lg:col-span-12 xl:col-span-8 2xl:col-span-12 3xl:col-span-6';
    } else if (props.cols === '3') {
      classes = 'col-span-full xs:col-span-full sm:col-span-full md:col-span-full lg:col-span-24 xl:col-span-16 2xl:col-span-12 3xl:col-span-9';
    } else if (props.cols === '4') {
      classes = 'col-span-full xs:col-span-full sm:col-span-full md:col-span-full lg:col-span-24 xl:col-span-20 2xl:col-span-16 3xl:col-span-12';
    } 
  } else {
    classes = 'col-span-full';
  }
  if (props.rows) {
    if (props.rows === '1') {
      classes += ' row-span-1';
    } else if (props.rows === '2') {
      classes += ' row-span-2';
    } else if (props.rows === '3') {
      classes += ' row-span-3';
    } else if (props.rows === '4') {
      classes += ' row-span-4';
    }
  } else {
    classes += ' row-span-1';
  }

  return (
    <div className={classNames([classes + ' ' + props.className + ' bg-white shadow-md rounded p-6 border-t-2 border-t-gray-100 relative'])}>
      {props.title && (<p className="text-lg font-medium mb-3 border-l-4 border-sky-600 pl-1.5 -ml-2.5">{props.title}</p>)}
      {/* <div className="xs:bg-red-200 sm:bg-orange-200 md:bg-pink-300 lg:mg-red:900 xl:bg-orange-200 2xl:bg-purple-200 3xl:bg-gray-200"> */}
      {props.children}
      {/* </div> */}
    </div>
  );
}
