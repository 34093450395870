import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { TrashIcon } from 'components/icons';

export default function ImageViewer({ url, onRemoveClick, canEdit }) {
  return (
    <div className="w-full h-full relative group">
      <img
        className={'object-scale-down h-full w-full rounded' + (canEdit ? ' group-hover:opacity-30' : '')}
        alt="IMG"
        src={url}
      />
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 hover:text-red-500 cursor-pointer absolute top-0 right-0 mr-1 opacity-0 group-hover:opacity-100"
        onClick={onRemoveClick}
        fill="none"
        viewBox="0 0 20 20"
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      </svg> */}
      { canEdit &&
      <div className='cursor-pointer absolute top-0 right-0 mr-1 opacity-0 group-hover:opacity-100 text-gray-700'>
        <Tooltip title="Slett">
          <IconButton>
            <div className='w-6 h-6'>
              <TrashIcon />
            </div>
          </IconButton>
        </Tooltip>
      </div>
      }
    </div>
  );
}