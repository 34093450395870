import { useRef } from 'react';
import { attachRenderFunctions } from './itemConfig';

export function CanvasView({ items, children }) {
  const svgRef = useRef();

  const renderItems = attachRenderFunctions(items);

  return (
    <>
      <svg ref={svgRef} id="PageViewer" viewBox="0 0 1500 570">
        {renderItems &&
          renderItems.map((item, index) => (
            <g key={index}>
              {item.render({
                key: index,
                id: index,
                state: item,
                children: children,
              })}
            </g>
          ))}
      </svg>
    </>
  );
}
