const { useState } = require('react');
const { sensorService } = require('services');

export function SensorReset({ value }) {
  const [reset, setReset] = useState(false);
  
  async function clickAction() {
    if (confirm('Er du sikker på at du vil fjerne all data og tilbakestille sensoren til fabrikkinnstillinger?')) {
      const res = await sensorService.reset(value);
      if(res) setReset(true);
    }
  }
  
  return (
    <>
      {!reset ? (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 hover:text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2} onClick={() => clickAction()}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
        </svg>
      )
        : (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
          </svg>
        )}
    </>
  );
}