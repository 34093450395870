import { sensorService } from 'services';
import { useSystemContext } from '../../context';

export default function SensorInvert() {
  const { selected, system, setLocalSystem, sensor } = useSystemContext();

  function changeInvert(e) {
    const res = { ...system };
    res.panels.filter((x) => x.position === selected)[0].sensor.invert = e;
    setLocalSystem(res);
    sensorService.updateSensor(sensor.id, { invert: e });
  }

  if (sensor.controller === 'vannvakt') {
    return (
      <div className="">
        {sensor.controller === 'vannvakt' && (
          <div>
            <p className='font-semibold'>Inverter</p>
            <button
              onClick={() => changeInvert(!sensor.invert)}
              className={'p-1 rounded py-2 px-4' + (sensor.invert ? ' bg-green-500 ' : ' bg-gray-400 ')}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
              </svg>
            </button>
          </div>
        )}
      </div>
    );
  }
}