import ChartComponent from 'react-apexcharts';

export function Chart({ data, update, zoom, unit }) {
  const options = {
    chart: {
      type: 'line',
      stacked: false,
      height: 'auto',
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: 'zoom',
        tools: {
          download:
            '<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-sky-600 -mt-5 md:-mt-1 md:-ml-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" /></svg>',
          zoom: '<div></div>',
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
      events: {
        beforeResetZoom: function (chartContext, opt) {
          zoom('');
          update();
        },
        beforeZoom: function (chart, { xaxis }) {
          zoom(xaxis.min);
          update(xaxis.min, xaxis.max);
        },
      },
      animations: {
        enabled: false,
      },
      locales: [
        {
          name: 'no',
          options: {
            months: [
              'Januar',
              'Februar',
              'Mars',
              'April',
              'Mai',
              'Juni',
              'Juli',
              'August',
              'September',
              'Oktober',
              'November',
              'Desember',
            ],
            shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'],
            days: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
            shortDays: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
            toolbar: {
              exportToSVG: 'Last ned SVG',
              exportToPNG: 'Last ned PNG',
              exportToCSV: 'Last ned CSV',
              menu: 'Last ned',
              selection: 'Seleksjon',
              selectionZoom: 'Seleksjon Zoom',
              zoomIn: 'Zoom Inn',
              zoomOut: 'Zoom Ut',
              pan: 'Panorer',
              reset: 'Reset Zoom',
            },
          },
        },
      ],
      defaultLocale: 'no',
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
    // title: {
    //   text: "Sensor.position",
    //   align: "left",
    // },
    yaxis: {
      labels: {
        formatter: function (val) {
          return `${val.toFixed(0)} ${unit}`;
        },
      },
    },
    xaxis: {
      type: 'datetime',
    },
    tooltip: {
      shared: false,
      x: {
        formatter: function (val) {
          return new Date(val).toLocaleString();
        },
      },
      y: {
        formatter: function (val) {
          return `${val.toFixed(1)} ${unit}`;
        },
      },
    },
    grid: {
      padding: {
        top: 20,
        bottom: 0,
      },
    },
  };

  return (
    <>
      {data ? (
        <>
          <ChartComponent options={options} series={data} />
        </>
      ) : (
        <div colSpan="4" className="flex flex-col justify-center min-h-screen/2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12 animate-pulse text-blue-500 place-self-center"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z"
            />
          </svg>
        </div>
      )}
    </>
  );
}
