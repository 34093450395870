import config from 'config';
import { fetchWrapper } from 'utils';

const baseUrl = `${config.deviceApiUrl}/samples`;

export const sampleService = {
  getById,
  getLive,
  delete: _delete,
};

function getById(sensorId, bucket, viewportMinimum, viewportMaximum, aggregate) {
  const query = {
    id: sensorId,
    bucket,
    start: viewportMinimum || new Date(0).getTime(),
    end: viewportMaximum || new Date().getTime(),
    aggregate,
    limit: 10000
  };
  return fetchWrapper.get(`${baseUrl}/?${new URLSearchParams(query)}`);
}

function getLive(sensorId) {
  const query = {
    id: sensorId,
  };
  return fetchWrapper.get(`${baseUrl}/?${new URLSearchParams(query)}`);
  // return fetchWrapper.get(`${baseUrl}/${sensorId}`);
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/?${id}`).then((x) => {
    // auto logout if the logged in user deleted their own record
    return x;
  });
}
