import { Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { Home } from './screens/home';
import { Footer } from './components/Footer';
import { Account } from './screens/account';
import { PrivateRoute } from './components/PrivateRoute';
import { System } from './screens/system';
import { Dashboard } from './screens/dashboard';
import { About } from './screens/about';
import { Profile } from './screens/profile';
import { SystemWrapper } from './screens/system/context';
import { Header } from './components/Header';
import { AppWrapper } from 'components/Context/AppWrapper';
import { NotFoundPage, ErrorBoundary } from 'screens/ErrorScreens';

function App() {

  return (
    <AppWrapper>
      <div className="App flex flex-col h-screen">
        <ErrorBoundary>
          <Toaster />
          <Header />
          <div className="flex-grow">
            <Routes>
              <Route index element={<Home />} />
              {/* <Route path="/about" element={<About />} /> */}
              {/* TODO: check if this is best place to have this route */}
              <Route
                path="/system/:id"
                element={
                  <PrivateRoute>
                    <SystemWrapper>
                      <System />
                    </SystemWrapper>
                  </PrivateRoute>
                }
              ></Route>
              <Route path="dashbord/*" element={<Dashboard />}></Route>
              {/* <Route
            path="admin/*"
            element={
              <PrivateRoute role={Role.Admin}>
              <Admin />
              </PrivateRoute>
            }
          ></Route> */}
              <Route path="account/*" element={<Account />} />
              <Route
                path="/profil/*"
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route path="*/*/*" index element={<NotFoundPage />} />
              <Route path="*/*" index element={<NotFoundPage />} />
              <Route path="*" index element={<NotFoundPage />} />
            </Routes>
          </div>
          <div className="flex-initial">
            <Footer />
          </div>
        </ErrorBoundary>
      </div>
    </AppWrapper>
  );
}

export default App;
