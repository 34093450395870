import { useMemo, useEffect, useState } from 'react';
import Table, { DatePill } from 'components/Modules/table/Table';
import { ContentContainer } from 'components/Containers/ContentContainer';
import { customerService } from 'services';
import { Select } from 'components/Elements/Select';
import { Widget } from '../components/Widget';

export function Alarms() {
  const [alarm, setAlarms] = useState(null);
  // TODO: add limit to backend, on whole query. Now it limits on each sensor. 
  const [limit, setLimit] = useState(1);
  useEffect(() => {
    customerService.getAllAlarms(limit).then((alarms) => {
      setAlarms(alarms);
    });
  }, [limit]);

  
  const alarmColumns = useMemo(
    () => [
      {
        Header: 'Lokasjon',
        accessor: 'locationTitle',
        cardClassName: 'font-bold text-[4vw] sm:text-xl',
      },
      {
        Header: 'System',
        accessor: 'systemTitle',
        cardClassName: 'italic text-[4vw] sm:text-xl',
      },
      {
        Header: 'Tag',
        accessor: 'sensorTag',
        cardClassName: 'italic text-[3vw]',
      },
      {
        Header: 'Verdi',
        accessor: 'dataValues.value',
        hideOnCard: true,
      },
      {
        Header: 'Tid',
        accessor: 'dataValues.time',
        Cell: DatePill,
      },
    ],
    []
  );

  return (
    <Widget>
      <ContentContainer>
        <div className="text-center mb-4">
          <h4>Alarmer</h4>
        </div>
        {alarm && <Table columns={alarmColumns} data={alarm} addToHeader={<Select state={limit} options={[1,5,10,20,50,200]} setState={setLimit} label={'Alarmer per sensor:'}/>} />}
      </ContentContainer>
    </Widget>
  );
}