import { useState } from 'react';

import { deviceService } from 'services';
import { useParams } from 'react-router';


export function DeviceTunnel() {
  const { id } = useParams();
  const [tunnel, setTunnel] = useState(null);

  return (
    <div className="flex flex-col space-y-1">
      <div>
        {!tunnel ? (
          <button
            onClick={() => {
              deviceService.createTunnel(id).then(() => {
                setTimeout(() => {
                  console.log('Call');
                  deviceService.getTunnel(id).then((x) => setTunnel(x));
                }, 5000);
              });
            }}
            className="rounded-lg py-1 px-3 border-2 border-black hover:bg-gray-200"
          >
              Generer tunnel
          </button>
        ) : (
          <table className="text-center">
            <thead>
              <tr>
                <th className="w-2/3">URL</th>
                <th className="w-1/3">Utløper</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="w-2/3">{tunnel.tunnel}</td>
                <td className="w-1/3">{new Date(tunnel.expiresAt).toLocaleString()}</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}