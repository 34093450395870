import { sensorService } from 'services';
import { DefaultInput } from 'components/Elements/Input';
import { useSystemContext } from '../../context';
import { useEffect, useState } from 'react';

export default function SensorOffset() {
  const { sensor } = useSystemContext();
  const [state, setState] = useState(0);

  useEffect(() => {
    setState(sensor?.offset || 0);
  }, [sensor]);

  function changeOffset(e) {
    setState(e.target.value);
    sensorService.updateSensor(sensor.id, {
      offset: e.target.value
    });
  }

  return (
    <div className="">
      <p className="font-semibold">Offset</p>
      <form>
        <DefaultInput 
          onChange={changeOffset} 
          value={state} 
          placeholder="Integer" 
          type="number" />
      </form>
    </div>
  );
}