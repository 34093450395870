import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { accountService } from 'services';
import toast from 'react-hot-toast';

function VerifyEmail() {
  const navigate = useNavigate();
  const location = useLocation();

  const EmailStatus = {
    Verifying: 'Verifying',
    Failed: 'Failed',
  };

  const [emailStatus, setEmailStatus] = useState(EmailStatus.Verifying);

  useEffect(() => {
    const { token } = location.search;

    // remove token from url to prevent http referer leakage
    navigate('');

    accountService
      .verifyEmail(token)
      .then(() => {
        toast.success('Bekreftelsen er vellykket. Du kan nå logge på.');
        navigate('login');
      })
      .catch(() => {
        setEmailStatus(EmailStatus.Failed);
      });
  }, []);

  function getBody() {
    switch (emailStatus) {
    case EmailStatus.Verifying:
      return <div>Bekrefter...</div>;
    case EmailStatus.Failed:
      return (
        <div>
            Bekreftelsen mislyktes. Du kan også bekrefte kontoen din ved hjelp av{' '}
          <Link to="forgot-password">glemt passord</Link> siden.
        </div>
      );
    }
  }

  return (
    <div>
      <h3 className="card-header">Bekreft e-post</h3>
      <div className="card-body">{getBody()}</div>
    </div>
  );
}

export { VerifyEmail };
