import { useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { accountService, customerService } from 'services';
import Table, { StatusPill } from 'components/Modules/table/Table';
import { ContentContainer } from 'components/Containers/ContentContainer';
import { Loading } from 'components/Modules/Loading';
import { DeviceTable } from 'components/Modules/table/DeviceTable';
import { CustomerTable } from 'components/Modules/table/CustomerTable';
import { Widget } from '../components/Widget';
import { useAppContext } from 'components/Context/AppWrapper';
import { GetSystemStatus } from 'utils/status';

export function AdminOverview() {
  const navigate = useNavigate();
  const [users, setUsers] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [systems, setSystems] = useState(null);

  const systemColumns = useMemo(
    () => [
      {
        Header: 'Tittel',
        accessor: 'title',
      },
      {
        Header: 'Beskrivelse',
        accessor: 'description',
      },
      {
        Header: 'Lokasjon',
        accessor: 'address',
      },
      {
        Header: 'Status',
        accessor: 'id',
        Cell: StatusPill,
        hideOnCard: true,
      },
    ],
    []
  );

  useEffect(() => {
    accountService.getAll().then((data) => setUsers(data));
    customerService.getAll().then((data) => {
      setCustomers(data);
      let temp = [];
      data.forEach((customer) => {
        customer.locations.forEach((location) => {
          location.systems.map((system) => {
            system.address = location.address;
            temp.push(system);
          });
        });
      });
      setSystems(temp);
    });
  }, []);

  function systemClick(object) {
    navigate(`/system/${object.id}`);
  }
  function customerClick(object) {
    navigate(`customers/${object.id}`);
  }

  return (
    <>
      <Widget>
        <div className="text-center mb-4">
          <h4>Systemer</h4>
          <p>Antall systemer: {systems && systems.length}</p>
        </div>
        {systems ? (
          <Table
            columns={systemColumns}
            cardsAsDefault={false}
            statusFunction={GetSystemStatus}
            data={systems}
            handleClick={systemClick}
          />
        ) : (
          <Loading />
        )}
      </Widget>
      
      <Widget>
        <DeviceTable clickPrefix={'enheter/'} />
      </Widget>
          
      <Widget>
        <CustomerTable clickPrefix={'kunder/'} />
      </Widget>
    </>
  );
}