import { useMemo, useState, useEffect } from 'react';
import { customerService, accountService } from 'services';
import Table, { EditButton, StatusPill, DatePill } from './Table';
import { ContentContainer } from 'components/Containers/ContentContainer';
import { useNavigate, useParams } from 'react-router';
import { Loading } from '../Loading';
import { hasAccess, Role } from 'utils';
import { GetLocationStatus, GetSystemStatus } from 'utils/status';

export function LocationTable({ cardsAsDefault }) {
  const navigate = useNavigate();
  const user = accountService.userValue;
  let { customerId } = useParams();
  if (!customerId) {
    customerId = customerService.customerValue && customerService.customerValue.id;
  }
  const [locations, setLocations] = useState(null);
  const locationColumns = useMemo(
    () => [
      {
        Header: 'Tittel',
        accessor: 'title',
        cardClassName: 'font-bold text-[4vw] sm:text-xl',
      },
      {
        Header: 'Adresse',
        accessor: 'address',
        cardClassName: 'italic text-[4vw] sm:text-xl',
      },
      {
        Header: 'Sist endret',
        accessor: 'updatedAt',
        Cell: DatePill,
        hideOnCard: true,
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: 'id',
        Cell: StatusPill,
        hideOnCard: true
      },
      {
        Header: ' ',
        accessor: 'id',
        Cell: ({ value }) => {
          return EditButton({ value, link: customerId });
        },
        hideOnCard: true,
        access: Role.Editor
      },
    ],
    [customerId]
  );

  function getCustomer() {
    customerService.getById(customerId).then((x) => {
      setLocations(x.locations);
    });
  }

  useEffect(() => {
    getCustomer();
  }, []);

  function removeLocation(locationId) {
    setLocations(
      locations.map((x) => {
        if (x.id === locationId) {
          x.isDeleting = true;
        }
        return x;
      })
    );
    customerService.removeLocation(locationId).then(() => {
      setLocations((locations) => locations.filter((x) => x.id !== locationId));
    });
  }

  function locationClick(object) {
    navigate(`${object.id}`);
  }

  function addClick() {
    navigate('add');
  }

  return (
    <ContentContainer>
      <div className="text-center mb-4">
        <h4>Mine Eiendommer</h4>
        <p>Antall eiendommer: {locations && locations.length}</p>
      </div>
      {locations ? (
        <Table columns={locationColumns} cardsAsDefault={cardsAsDefault} statusFunction={GetLocationStatus} data={locations} handleClick={locationClick} addButton={hasAccess(user, Role.CustomerAdmin) ? addClick : false} />
      ) : (
        <Loading />
      )}
    </ContentContainer>
  );
}
