import { Widget, WidgetGrid } from 'screens/dashboard/components/Widget';
import { Route, Routes } from 'react-router-dom';
import { DashboardContainer } from 'screens/dashboard/context';
import { DashboardWrapper } from 'screens/dashboard/components/DashboardWrapper';
import { profileRoutes } from 'screens/dashboard/routes';
import { Notifications } from './Notifications';
import { UpdateProfile } from './Profile';
import { hasAccess } from 'utils';
import { accountService } from 'services';
import { SystemNotifications } from './SystemNotifications';

export function Profile() {
  const user = accountService.userValue;

  const activeRoutes = [];
  profileRoutes.map(route => {
    if (route.noAccess) {
      if (hasAccess(user, route.access, route.noAccess)) {
        activeRoutes.push(route);
      }
    } else {
      if (hasAccess(user, route.access)) {
        activeRoutes.push(route);
      }
    }
  });
  return (
    <DashboardContainer>
      <Routes>
        {activeRoutes?.map((elem) => (
          <Route key={elem.path} path={elem.path} element={
            <DashboardWrapper title={elem.name} menuItems={activeRoutes} hidePath>
              <elem.element />
            </DashboardWrapper>
          } />
        ))}
      </Routes>
    </DashboardContainer>
    // <Routes>
    //   <Route path={'update'} element={<UpdateProfile />} />
    //   <Route path="settings" element={<Settings />} />
    //   <Route path="notifications" element={<Notifications />} />
    // </Routes>
  );
}

export function ProfileNotifications() {
  return (
    <>
      <Widget title="System">
        <SystemNotifications />
      </Widget>
      <Widget title="Sensor">
        <Notifications />
      </Widget>
    </>
  );
}